import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {getUserStats} from "../../../actions/adminAction";

const color = [
    '#0AD10A',
    '#FF0000',
    '#289EF8'
]
const AdminInfoCard = () => {

    const { stats } = useSelector(state=> state.getUserStat);

    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cardInfo = stats?[
        {
            name : 'Total Players',
            value : stats.userCount
        },
        {
            name : 'Total Amount',
            value : stats.totalWalletCoins
        },
        {
            name : 'Total Winners',
            value  : '12'
        }
    ]:[]
    const renderedCard = cardInfo.map((card,index) =>{
       return (
           <div className={`border font-bold justify-center w-96  items-center h-40 flex flex-col text-white`} style={{backgroundColor:color[index]}}>
               <h1 className={'text-6xl'}>{card.value}</h1>
               <h1 className={'text-xl'}>{card.name}</h1>
           </div>

       )
    });
    const fetchStats = async () =>{
        await dispatch(getUserStats(token))
    }
    useEffect(() => {
        if(!isAuthenticated || ! adminUser || !token){
            navigate('/adminLogin')
        }
        console.log(token)
        fetchStats()
    }, [dispatch]);
    return (
        <div className={'w-full flex flex-row flex-wrap md:flex-nowrap justify-center items-center gap-10'}>
            {renderedCard}
        </div>
    )
}
export default AdminInfoCard
