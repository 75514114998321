import SubNavbar from "../components/SubNavbar";
import React from "react";
import Layout from "../components/Dashboard/Layout";
import {Outlet} from "react-router-dom";

const ProfileLayout = ({children}) =>{
    const navbarElement = [
        {
            name : 'Edit Profile',
            path : '/profile/edit'
        },
        {
            name : 'Change Password',
            path : '/profile/changepassword'
        }
    ]
    return (
        <Layout className={'flex flex-col gap-10 mb-10'}>
            <SubNavbar navbarElement={navbarElement} className={'ml-10 flex flex-row gap-7'}/>
            <Outlet />
        </Layout>
    )
}
export default ProfileLayout
