import React from 'react';
import { motion } from 'framer-motion';

const LoadingScreen = () => {
    const loadingVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: { duration: 0.5 },
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.3 },
        },
    };

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={loadingVariants}
            className="fixed top-0 left-0 w-full h-full bg-transparent z-50 flex flex-col items-center justify-center"
        >
            <motion.div variants={loadingVariants} className="w-20 h-20 rounded-full border-2 border-white animate-spin"></motion.div>
            <p className="text-black text-xl mt-4">Loading...</p>
        </motion.div>
    );
};

export default LoadingScreen;
