export const extractDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    console.log("Extracted Date :", dateObj.toLocaleDateString('en-US', options));
    return dateObj.toLocaleDateString('en-US', options); // Format the date
};
export const extractDay = (dateStr) => {
    const dateObj = new Date(dateStr);
    const day = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
    return day;
};

export function extractTimeString(datetimeObj, format = "%H:%M:%S") {
    console.log(datetimeObj);
    let date = new Date(datetimeObj);

    // Handle seconds exclusion if needed
    if (!format.includes("%S")) {
        const newDatetimeObj = new Date(date.getTime()); // Create a copy
        newDatetimeObj.setSeconds(0);
        newDatetimeObj.setMilliseconds(0);
        date = newDatetimeObj; // Update the original reference
    }

    // Specify the timezone to keep the time in UTC
    const options = { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    console.log(date.toLocaleTimeString([], options))
    return date.toLocaleTimeString([], options);
}

export function teamShortName(str) {
    // Split the string into an array of words
    const words = str.split(' ');

    // Initialize an empty array to store the first letters
    const firstLetters = [];

    // Iterate through each word in the array
    for (const word of words) {
        // Extract the first letter of the word and convert it to uppercase
        const firstLetter = word.charAt(0).toUpperCase();

        // Push the first letter to the firstLetters array
        firstLetters.push(firstLetter);
    }

    // Join the first letters array into a string and return it
    return firstLetters.join('');
}
