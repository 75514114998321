export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";


export const LOAD_USER_FAIL = "LOAD_USER_FAIL";
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";

export const LOAD_WALLET_HISTORY_FAIL = "LOAD_WALLET_HISTORY_FAIL"
export const LOAD_WALLET_HISTORY_REQUEST = "LOAD_WALLET_HISTORY_REQUEST"
export const LOAD_WALLET_HISTORY_SUCCESS = "LOAD_WALLET_HISTORY_SUCCESS"
