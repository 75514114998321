import axios from "../utils/axiosConfig";
import {
    ADD_MATCH_ADMIN_FAIL,
    ADD_MATCH_ADMIN_REQUEST,
    ADD_MATCH_ADMIN_SUCCESS,
    ADD_TOSS_WINNER_ADMIN_FAIL,
    ADD_TOSS_WINNER_ADMIN_REQUEST,
    ADD_TOSS_WINNER_ADMIN_SUCCESS,
    GET_USER_STATS_FAIL,
    GET_USER_STATS_REQUEST,
    GET_USER_STATS_SUCCESS,
    LIST_DEPOSIT_ADMIN_FAIL,
    LIST_DEPOSIT_ADMIN_REQUEST,
    LIST_DEPOSIT_ADMIN_SUCCESS,
    LIST_MATCHES_ADMIN_FAIL,
    LIST_MATCHES_ADMIN_REQUEST,
    LIST_MATCHES_ADMIN_SUCCESS, LIST_WITHDRAW_ADMIN_FAIL, LIST_WITHDRAW_ADMIN_REQUEST, LIST_WITHDRAW_ADMIN_SUCCESS,
    LOGIN_ADMIN_USER_FAIL,
    LOGIN_ADMIN_USER_REQUEST,
    LOGIN_ADMIN_USER_SUCCESS,
    UPDATE_DEPOSIT_ADMIN_FAIL,
    UPDATE_DEPOSIT_ADMIN_REQUEST,
    UPDATE_DEPOSIT_ADMIN_SUCCESS,
    UPDATE_LIST_PAYMENT_FAIL,
    UPDATE_LIST_PAYMENT_REQUEST,
    UPDATE_LIST_PAYMENT_SUCCESS,
    UPDATE_MATCH_STATUS_ADMIN_FAIL,
    UPDATE_MATCH_STATUS_ADMIN_REQUEST,
    UPDATE_MATCH_STATUS_ADMIN_SUCCESS
} from "../constants/adminConstants";
import {LIST_WITHDRAW_FAIL, LIST_WITHDRAW_REQUEST, LIST_WITHDRAW_SUCCESS} from "../constants/withdrawConstants";
import {
    LIST_DEPOSIT_FAIL,
    LIST_DEPOSIT_REQUEST,
    LIST_DEPOSIT_SUCCESS, NEW_DEPOSIT_FAIL,
    NEW_DEPOSIT_REQUEST, NEW_DEPOSIT_SUCCESS
} from "../constants/depositConstants";

export const loginAdminUser = (userName, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_ADMIN_USER_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.post(
            '/api/v1/adminLogin',
            { userName, password },
            config
        );

        dispatch({
            type: LOGIN_ADMIN_USER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LOGIN_ADMIN_USER_FAIL,
            payload: error.message,
        });
    }
}
export const getUserStats = (token) => async (dispatch) =>{
    try {
        dispatch({ type: GET_USER_STATS_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.get(
            '/api/v1/userStat',
            config
        );

        dispatch({
            type: GET_USER_STATS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_STATS_FAIL,
            payload: error.message,
        });
    }}

export const getDepositAdmin = (token) => async (dispatch) =>{
    try {
        dispatch({ type: LIST_DEPOSIT_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.get(
            '/api/v1/getAllDepositAdmin',
            config
        );

        dispatch({
            type: LIST_DEPOSIT_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_DEPOSIT_ADMIN_FAIL,
            payload: error.message,
        });
    }
}
export const getWithdrawAdmin = (token) => async (dispatch) =>{
    try {
        dispatch({ type: LIST_WITHDRAW_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.get(
            '/api/v1/getAllWithdrawAdmin',
            config
        );

        dispatch({
            type: LIST_WITHDRAW_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_WITHDRAW_ADMIN_FAIL,
            payload: error.message,
        });
    }
}

export const addMatchAdmin = (token,matchData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_MATCH_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.post(
            '/api/v1/addMatch',
            matchData,
            config
        );
        console.log(data)
        dispatch({
            type: ADD_MATCH_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_MATCH_ADMIN_FAIL,
            payload: error.message,
        });
    }}
export const getAllMatches = (token) => async (dispatch) =>{
    try {
        dispatch({ type: LIST_MATCHES_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.get(
            '/api/v1/getAllMatches',
            config
        );

        dispatch({
            type: LIST_MATCHES_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_MATCHES_ADMIN_FAIL,
            payload: error.message,
        });
    }
}
export const updateTossWinner = (token,matchId,winner) => async (dispatch) =>{
    try {
        dispatch({ type: ADD_TOSS_WINNER_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.put(
            '/api/v1/updateTossWinner',
            { matchId,winner },
            config
        );

        dispatch({
            type: ADD_TOSS_WINNER_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_TOSS_WINNER_ADMIN_FAIL,
            payload: error.message,
        });
    }
}
export const updateMatchStatus = (token,matchId) => async (dispatch) =>{
    try {
        dispatch({ type: UPDATE_MATCH_STATUS_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.put(
            '/api/v1/closeTheMatch',
            { matchId },
            config
        );

        dispatch({
            type: UPDATE_MATCH_STATUS_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_MATCH_STATUS_ADMIN_FAIL,
            payload: error.message,
        });
    }
}
export const editMatch = (token,matchId,date) => async (dispatch) =>{
    try {
        dispatch({ type: UPDATE_MATCH_STATUS_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        console.log(date)
        const { data } = await axios.put(
            '/api/v1/editTheMatch',
            { matchId,date },
            config
        );

        dispatch({
            type: UPDATE_MATCH_STATUS_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_MATCH_STATUS_ADMIN_FAIL,
            payload: error.message,
        });
    }
}
export const updateBankDetails = (token,bankData) => async (dispatch) =>{
    try {
        dispatch({ type: UPDATE_LIST_PAYMENT_REQUEST });
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":"Bearer "+token
            },
        }
        const { data } = await axios.put(
            '/api/v1/updatebank',
            bankData,
            config
        );

        dispatch({
            type: UPDATE_LIST_PAYMENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_LIST_PAYMENT_FAIL,
            payload: error.message,
        });
    }
}