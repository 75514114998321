import Banner from "../components/Banner";
import Advertise from "../components/Advertise";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import FormCard from "../components/Form/FormCard";
import TextInput from "../components/Form/TextInput";
import { useEffect, useState } from "react";
import Button from "../components/Form/Button";

import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { registerUser } from "../actions/userAction";
import LoadingScreen from "../components/Form/Loading";
import {toast} from "react-toastify";
import {CLEAR_ERRORS} from "../constants/userConstant";
const RegisterPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { loading, isAuthenticated, error, user, token } = useSelector((state) => state.user);

    const [isFocused, setIsFocused] = useState(false);
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        if(isAuthenticated && token && user){
            toast.success('Register Successful');
            navigate('/dashboard')
        }
    }, [token,isAuthenticated,user])

    useEffect(()=>{
        if(error){
            toast.error(error.response.data.message);
            dispatch({type : CLEAR_ERRORS})
        }
    },[error])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(username == ''){
            toast.error('Username cannot be empty');
            return;
        }
        if(password == ''){
            toast.error('Password cannot be empty');
            return;
        }
        if(confirmPassword == ''){
            toast.error('Confirm Password cannot be empty');
            return;
        }
        if (password != confirmPassword) {
            toast.error('Password and Confirm Password are not matching');
            return
        }
        if (username && password && confirmPassword) {
            await dispatch(registerUser(username, password))
        }
    }

    return ((loading) ? <LoadingScreen/>:
        <div className={'flex flex-col items-center gap-10 justify-center mb-10'}>
            <Banner className={'relative h-56 lg:h-96'} bannerImage={require('../assests/images/banner.png')}>
                <Advertise
                    className={'absolute bottom-4 left-28 md:bottom-4 md:left-10 sm:gap-2 lg:gap-10 text-center p-4 flex flex-col '}
                    // title={'Indian Premier League'}

                    // subTitle={'Use your free bet on champion league match and win incredible prizes'}
                    // action={() => {
                    //     console.log('Clicked')
                    // }}/>
                >

                    <img className={'h-36 w-36 mx-auto'} src={require("../assests/images/logo.png")}/>
                </Advertise>
            </Banner>
            <div className={'flex flex-col items-center gap-10'}>
                <Title displayText={'REGISTER TO PLACE BETS'} />
                {/*<SubTitle displayText={'Gaming Helper is the most advanced sports trading & affialiate platform and highest stakes across multiple bookmakers and exchanges.'} />*/}
            </div>
            <FormCard className={'w-[95%] sm:w-1/2 xl:w-1/3 flex flex-col gap-7 justify-center items-center'} onSubmit={handleSubmit}>
                {/*<div className={'flex flex-col lg:flex-row gap-10 w-full'}>*/}
                {/*    <TextInput*/}
                {/*        className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}*/}
                {/*        type="text"*/}
                {/*        placeholder={'Enter First Name'}*/}
                {/*        value={firstName}*/}
                {/*        onChange={(e) => setFirstName(e.target.value)}*/}
                {/*        onFocus={() => setIsFocused(true)}*/}
                {/*        onBlur={() => setIsFocused(false)}*/}
                {/*    />*/}
                {/*    <TextInput*/}
                {/*        className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}*/}
                {/*        type="text"*/}
                {/*        placeholder={'Enter Last Name'}*/}
                {/*        value={lastName}*/}
                {/*        onChange={(e) => setLastName(e.target.value)}*/}
                {/*        onFocus={() => setIsFocused(true)}*/}
                {/*        onBlur={() => setIsFocused(false)}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className={'flex flex-col lg:flex-row gap-10 w-full'}>
                    <TextInput
                        className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}
                        type="text"
                        placeholder={'Enter Username'}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                    {/*<TextInput*/}
                    {/*    className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}*/}
                    {/*    type="text"*/}
                    {/*    placeholder={'Enter Phone Number'}*/}
                    {/*    value={phoneNumber}*/}
                    {/*    onChange={(e) => setPhoneNumber(e.target.value)}*/}
                    {/*    onFocus={() => setIsFocused(true)}*/}
                    {/*    onBlur={() => setIsFocused(false)}*/}
                    {/*/>*/}
                </div>
                <div className={'flex flex-col lg:flex-row gap-10 w-full'}>
                    <TextInput
                        className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}
                        type="password"
                        placeholder={'Enter Password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                    <TextInput
                        className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}
                        type="password"
                        placeholder={'Confirm Password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                </div>
                <Button className={'rounded-lg p-4 px-10'} type={'submit'}>
                    Register
                </Button>
                <div className={'flex flex-row gap-10'}>
                    <p>
                        Already have an account?  <Link to={'/login'}>
                        Login
                    </Link>
                    </p>
                </div>
            </FormCard>
        </div>
    )
}
export default RegisterPage;
