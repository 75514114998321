import AdminInfoCard from "../components/Admin/Dashboard/AdminInfoCard";
import MyTable from "../components/Form/Table";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Button from "../components/Form/Button";
import React, {useEffect} from "react";
import {getDepositAdmin} from "../actions/adminAction";
import {updateDepositStatus} from "../actions/depositAction";
import LoadingScreen from "../components/Form/Loading";
import {BASE_SCREENSHOT_IMAGE_URL} from "../utils/constant";

const DepositList = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);
    const {listDepositsAdmin} = useSelector(state=>state.listDepositsAdmin);
    const {success} = useSelector(state=>state.updateDepositStatus);

    const fetchDeposite = async ()=>{
        await dispatch(getDepositAdmin(token));
    }

    const onHandleAction = async (id,approve) =>{
        await dispatch(updateDepositStatus(token,id,approve));
    }
    useEffect(() => {
        if(!isAuthenticated || ! adminUser || !token){
            navigate('/adminLogin')
        }
        fetchDeposite()
    }, [dispatch,success]);

    const renderedData = listDepositsAdmin?listDepositsAdmin.map((value,index) =>{
        if(value.status === 'pending'){
            return {
                srno : index+1,
                username : value.username,
                transactionId : value.transactionId,
                depositAmount : value.amount,
                status : value.status,
                screenshot : <a target="_blank" rel="noopener noreferrer" href={BASE_SCREENSHOT_IMAGE_URL+value.screenshot}>{value.screenshot}</a>,
                action : <div className={'flex flex-row gap-2 justify-center'}>
                    <Button className={'rounded-md bg-transparent border  px-2'} onClick = {()=>{
                        onHandleAction(value._id,true)
                    }}>
                        Accept
                    </Button>
                    <Button className={'rounded-md bg-transparent border px-2'} onClick = {()=>{
                        onHandleAction(value._id,false)

                    }}>
                        Reject
                    </Button>
                </div>
            }
        }else{
            return {
                srno : index+1,
                username : value.username,
                transactionId : value.transactionId,
                depositAmount : value.amount,
                status : value.status,
                screenshot : <a target="_blank" rel="noopener noreferrer" href={BASE_SCREENSHOT_IMAGE_URL+value.screenshot}>{value.screenshot}</a>,
            }
        }

    }) : []

    const columns = [
    { Header: 'Srno', accessor: 'srno' },
    { Header : 'Username',accessor:  'username'},
    { Header: 'Transaction ID', accessor: 'transactionId' },
    { Header: 'Deposit Amount', accessor: 'depositAmount' },
    { Header: 'Payment status', accessor: 'status' },
    { Header: 'Screenshot', accessor: 'screenshot' },
    { Header: 'Action', accessor: 'action' },
    ]
    return (
        (loading) ? <LoadingScreen/>:
        <div className={'flex flex-col gap-10 p-4 justify-center  mt-5'}>
            <h1 className={'text-3xl'}>Deposit List</h1>
            <AdminInfoCard/>
            <MyTable columns={columns} data={renderedData}/>
        </div>
    )
}
export default DepositList;
