import AdminInfoCard from "../components/Admin/Dashboard/AdminInfoCard";
import MyTable from "../components/Form/Table";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {editMatch, getAllMatches, updateMatchStatus, updateTossWinner} from "../actions/adminAction";
import {extractDate, extractTimeString} from "../utils";
import Button from "../components/Form/Button";
import SelectField from "../components/Form/SelectField";
import {ADD_TOSS_WINNER_ADMIN_RESET, UPDATE_MATCH_STATUS_ADMIN_RESET} from "../constants/adminConstants";
import DateTime from "../components/Form/DateTime";
import {toast} from "react-toastify";

const ListMatch = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {listAllMatches} = useSelector(state => state.listAllMatches)
    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);
    const {success,match} = useSelector(state=>state.updateToss)
    const {isUpdated} = useSelector(state=>state.updateMatchStatus)
    const [editMode, setEditMode] = useState({});
    const [dateObj,setDateObj] = useState({})
    const [selectedTossWinners, setSelectedTossWinners] = useState([]);
    const handleTossWinnerChange = (matchId, value) => {
        setSelectedTossWinners({
            ...selectedTossWinners,
            [matchId]: value
        });
    }

    const onHandleDateChange = (id, newDateValue) => {
        // Assuming 'dateObj' is your state object holding dates for matches
        // and it's structured like { [matchId]: dateValue }

        // Create a new object with the updated date for the given match ID
        setDateObj(prevDateObj => ({
            ...prevDateObj, // Spread the previous state to maintain other dates
            [id]: newDateValue // Update the date for the given match ID
        }));
    };

    const toggleEditMode = (id) => {
        setEditMode(prev => ({ ...prev, [id]: !prev[id] }));
    };
    const onHandleTossWinner = async  (matchId) =>{
        if(!selectedTossWinners[matchId]){
            console.log('Not Selected')
        }else{
            await dispatch(updateTossWinner(token,matchId,selectedTossWinners[matchId]))
        }
    }

    const onHandleMatchStatus = async  (matchId) =>{
        await dispatch(updateMatchStatus(token,matchId))
    }

    const onHandleEditMatch = async (matchId) => {
        console.log("Entered")
        await dispatch(editMatch(token,matchId,dateObj[matchId]))
    }
    const fetchGetMatch = async () =>{
        await dispatch(getAllMatches(token))
    }
    useEffect(() => {
        if(!isAuthenticated || ! adminUser || !token){
            navigate('/adminLogin')
        }
        fetchGetMatch()
    }, [token,adminUser]);

    useEffect(()=>{
        if(success){
            fetchGetMatch();
            dispatch({type : ADD_TOSS_WINNER_ADMIN_RESET})
        }
        if(isUpdated){
            fetchGetMatch();
            dispatch({type : UPDATE_MATCH_STATUS_ADMIN_RESET})
        }
    },[success,dispatch,isUpdated])

    const renderedData  = listAllMatches? listAllMatches.map((match,index)=>{
        return (
            {
                matchNo : match.matchNo,
                teamA: match.teamA,
                teamB: match.teamB,
                date : (editMode[match._id]) ? <DateTime type={'datetime-local'} labelText={'Date:'} value={dateObj[match._id] ? dateObj[match._id] : new Date()}
                                                         onChange={(e) => onHandleDateChange(match._id, e.target.value)}/> : extractDate(match.date) + " " + extractTimeString(match.date) ,
                venue : match.venue,
                status : match.status,
                bets:match.totalBets,
                amount : match.totalBetAmount,
                tossWinner : (!match.tossWinner && match.status ==='close')?
                    <div key={match._id} className={'flex flex-col lg:flex-row justify-center items-center gap-4 '}>
                        <SelectField title={'Who Wom the Toss'} options={[match.teamA,match.teamB]} selectedValue={selectedTossWinners[match._id]} setSelectedValue={(value) =>{
                            handleTossWinnerChange(match._id,value)
                        }}></SelectField>
                        <Button className={'px-2 rounded-md text-white'} onClick={()=>{
                            onHandleTossWinner(match._id)
                        }}>
                            Submit
                        </Button>
                    </div>
                    :<div className={'flex flex-row justify-center items-center'}>
                        {match.tossWinner}
                    </div>,
                action : <div className={'flex flex-col gap-2'}>
                    {
                        (match.status === 'open') ?
                            <Button className={'px-2 text-white'} onClick={()=>{
                                onHandleMatchStatus(match._id)
                            }}>
                                Close
                            </Button>
                            :'Closed'
                    }
                    {
                        (!editMode[match._id])?
                            <Button className={'px-2 bg-transparent border'} onClick={() => toggleEditMode(match._id)}>
                                Edit
                            </Button>:
                            <Button className={'px-2 text-white'} onClick={() => {
                                toggleEditMode(match._id,false)
                                if(dateObj[match._id] !== match.date){
                                    toast.info('Changed Date Wait For Some Time...');
                                    onHandleEditMatch(match._id)
                                }
                            }}>
                                Submit
                            </Button>
                    }
                </div>
            }
        )
    }):[];


    const columns = [
        { Header: 'Match No', accessor: 'matchNo' },
        { Header: 'Team A', accessor: 'teamA' },
        { Header: 'Team B', accessor: 'teamB' },
        { Header: 'Date', accessor: 'date' },
        // { Header: 'Time', accessor: 'time' },
        { Header: 'Venue', accessor: 'venue' },
        { Header: 'Status', accessor: 'status'},
        { Header: 'Bets', accessor:'bets'},
        { Header: 'Amount', accessor:'amount'},
        { Header: 'Toss Winner', accessor: 'tossWinner' },
        { Header : 'Action' , accessor:  'action'},

    ]

    return (
        <div className={'flex flex-col gap-10 p-4 mt-5'}>
            <h1 className={'text-3xl'}>Schedule Match</h1>
            <AdminInfoCard/>
            <MyTable columns={columns} data={renderedData}/>
        </div>
    )
}
export default ListMatch;

