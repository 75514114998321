import Title from "../Title";
import FormCard from "../Form/FormCard";
import TextInput from "../Form/TextInput";
import Button from "../Form/Button";

const EditPassword = () =>{
    return (
        <>
            <Title displayText={'Edit Password'}/>
            <FormCard className={'flex flex-col gap-10 w-1/2 mx-auto'}>
                <div className={'flex flex-row gap-5'}>
                    <TextInput className={'w-full'} placeholder={'Enter Phone No'}/>
                    <TextInput className={'w-full'} placeholder={'Enter Current Password'}/>
                </div>
                <div className={'flex flex-row gap-5'}>
                    <TextInput className={'w-full'} placeholder={'Enter Password'}/>
                    <TextInput className={'w-full'} placeholder={'Confirm Password'}/>
                </div>
                <Button className={'py-2 rounded-md w-1/5 mx-auto'}>Submit</Button>
            </FormCard>
        </>
    )
}
export default EditPassword
