import Title from "../components/Title";
import MatchCard from "../components/Dashboard/MatchCard";
import SubTitle from "../components/SubTitle";
import SelectField from "../components/Form/SelectField";
import TextInput from "../components/Form/TextInput";
import Button from "../components/Form/Button";
import Layout from "../components/Dashboard/Layout";
import React, {useEffect} from "react";
import Table from "../components/Form/Table";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getTodaysMatch} from "../actions/matchAction";
import {getBetMatch, getBetMatchByTeamName} from "../actions/betAction";
import {extractDate, extractTimeString} from "../utils";
import LoadingScreen from "../components/Form/Loading";

const WinnerList = () =>{
    const dispatch = useDispatch();
    const {betMatchesByTeam,loading}=useSelector(state=>state.listBetMatchTeam)
    const {token,user,isAuthenticated} = useSelector(state=> state.user);
    const navigate = useNavigate()
    const fetchBetMatches = async () =>{
        await dispatch(getBetMatchByTeamName(token))

    }
    useEffect(() => {
        if(!user || !isAuthenticated || !token){
            navigate('/login')
        }
        fetchBetMatches()
    }, []);

    const renderedData  = betMatchesByTeam? betMatchesByTeam.map((match,index)=>{
        return (
            {
                matchNo : match.matchId.matchNo,
                teamA: match.matchId.teamA,
                teamB: match.matchId.teamB,
                date :extractDate(match.matchId.date),
                time : extractTimeString(match.matchId.date),
                venue : match.matchId.venue,
                predictToss : match.predictToss,
                amount :  match.predictAmount,
                status : match.status,
                tossWinner : match.matchId.tossWinner
            }
        )
    }):[];
    const columns = [
        { Header: 'Match No', accessor: 'matchNo' },
        { Header: 'Team A', accessor: 'teamA' },
        { Header: 'Team B', accessor: 'teamB' },
        { Header: 'Date', accessor: 'date' },
        { Header: 'Time', accessor: 'time' },
        { Header: 'Venue', accessor: 'venue' },
        { Header: 'Predicted Toss', accessor: 'predictToss' },
        { Header: 'Amount', accessor: 'amount' },
        { Header: 'Status', accessor: 'status'},
        { Header: 'Toss Winner', accessor: 'tossWinner' },

    ]
    return (
            // (loading) ? <LoadingScreen/>:
        <Layout className={'flex flex-col w-full h-full gap-10 mb-10'}>
            <div className={'ml-10 flex flex-row items-center gap-5'}>
                <div className={'w-2 h-2 rounded bg-red-600'}></div>
                <text className={'text-lg font-bold'}>Bet List</text>
                <div className={'w-1/12 h-1 bg-red-600'}></div>
            </div>
            <Table className={'w-2/3 mx-auto rounded-md border-[#FFFFFF] shadow drop-shadow-md'} data={renderedData}
                   columns={columns}/>
        </Layout>
    )
}
export default WinnerList
