import FormCard from "../Form/FormCard";
import {BASE_LOGO_IMAGE_URL, teamLogo, teams} from "../../utils/constant";
import {extractDate, extractDay, teamShortName} from "../../utils";

const VersusCard = ({teamA,teamB,date,time,teamALogo,teamBLogo}) =>{
    console.log("Time :" ,time)
    return (
        <FormCard className={'flex flex-col sm:flex-row gap-4 rounded lg:w-1/2 items-center justify-between'}>
            <img className={'max-h-24 lg:max-h-16'} src={BASE_LOGO_IMAGE_URL + teamALogo}/>
            <div className={'flex flex-col text-lg font-bold text-center'}>
                <text>{teamA}</text>
                <text>{extractDay(date) + " " + extractDate(date)}</text>
            </div>
            <img className={'max-h-24 '} src={require('../../assests/images/versus.png')}/>
            <div className={'flex flex-col text-lg font-bold text-center'}>
                <text>{teamB}</text>
                <text>{time}</text>
            </div>
            <img className={'max-h-24 lg:max-h-16'}src={BASE_LOGO_IMAGE_URL + teamBLogo}/>
        </FormCard>
    )
}
export default VersusCard
