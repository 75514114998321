import classnames from "classnames";

const Banner = ({className,bannerImage,children}) =>{
    const classNames = classnames(className,'w-full')

    return (
        <div className={classNames}>
            <img className={'w-full h-full object-cover'} src={bannerImage} alt={'Unable to Render Banner Image'}/>
            {children}
        </div>
    )
}
export default  Banner
