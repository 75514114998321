import Table from "../components/Form/Table";
import Layout from "../components/Dashboard/Layout";
import React, {useEffect} from "react";
import SubNavbar from "../components/SubNavbar";
import {extractDate} from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {loadWalletHistory} from "../actions/userAction";
import LoadingScreen from "../components/Form/Loading";
import {BASE_SCREENSHOT_IMAGE_URL} from "../utils/constant";

const WalletHistoryPage = () =>{
    const {user,isAuthenticated,token} = useSelector(state => state.user);
    const {listWalletHistory,loading} = useSelector(state=>state.walletHistory);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const navbarElement = [
        {
            name : 'Deposit',
            path : '/deposite'
        },
        {
            name : 'Deposit History',
            path : '/depositeHistory'
        },
        {
            name : 'Wallet History',
            path : '/walletHistory'
        }
    ]

    const fetchWalletHistory = async () =>{
        await dispatch(loadWalletHistory(token))
    }
    useEffect(() => {
        if(!user || !isAuthenticated || !token){
            navigate('/login')
        }
        fetchWalletHistory()
    }, []);
    const renderedData = listWalletHistory ?  listWalletHistory.map((data,index)=>{
        return (
            {
                srno : index+1,
                transactionId :data.transactionId,
                upiId : data.upiId? data.upiId : '-',
                screenshot : <a target="_blank" rel="noopener noreferrer" href={BASE_SCREENSHOT_IMAGE_URL+data.screenshot}>{data.screenshot}</a>,
                depositAmount:data.amount,
                type : data.transactionType,
                status : data.status,
                date : extractDate(data.createdAt)
            }
        )
    }) : null

    const columns = [
        { Header: 'Srno', accessor: 'srno' },
        { Header: 'TransactionId', accessor: 'transactionId' },
        { Header:  'UPI ID',accessor: "upiId"},
        { Header: 'Screenshot', accessor: 'screenshot' },
        { Header : 'Type', accessor: 'type'},
        { Header: 'DepositAmount', accessor: 'depositAmount' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Date', accessor: 'date' },
    ]
    return (
        (loading) ? <LoadingScreen/>:
            <Layout className={'flex flex-col gap-10 mb-10 w-full '}>
            <SubNavbar navbarElement={navbarElement} className={'ml-2 lg:ml-10 flex flex-row gap-7'} />
            <div className={'ml-10 flex flex-row items-center gap-5'}>
                <div className={'w-2 h-2 rounded bg-red-600'}></div>
                <text className={'text-lg font-bold'}>Wallet History</text>
                <div className={'w-1/12 h-1 bg-red-600'}></div>
            </div>
            <Table className={'overflow-x-auto rounded-md border-[#FFFFFF] shadow drop-shadow-md overflow-scroll '} data={renderedData?renderedData:null}
                   columns={columns}/>
        </Layout>
    )
}
export default WalletHistoryPage
