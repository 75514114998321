import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {listWalletReducer, userReducer} from "./reducers/userReducer";
import {betReducer, listBettingMatchesByDetailsReducer, listBettingMatchesReducer} from './reducers/betReducer';
import {listMatchesReducer, matchReducer} from "./reducers/matchReducer"
import {listDepositReducer, listPaymentReducer, newDepositReducer} from "./reducers/depositReducer";
import {listWithdrawReducer, newWithdrawReducer} from "./reducers/withdrawReducer";
import {
    addMatchAdminReducer,
    adminReducer,
    depositAdminReducer, listAllMatchesReducers,
    listDepositAdminReducer, listWithdrawAdminReducer, updateMatchStatus, updatePaymentReducer, updateTossWinner,
    userStatsAdminReducer, withdrawAdminReducer
} from "./reducers/adminReducer";

const reducer = combineReducers({
    user: userReducer,
    bet: betReducer,
    match: matchReducer,
    listMatches : listMatchesReducer,
    listBetMatches : listBettingMatchesReducer,
    newDeposit : newDepositReducer,
    listDeposit : listDepositReducer,
    newWithdraw : newWithdrawReducer,
    listWithdraw : listWithdrawReducer,
    walletHistory : listWalletReducer,
    adminUser :adminReducer,
    getUserStat : userStatsAdminReducer,
    listDepositsAdmin :listDepositAdminReducer,
    listWithdrawAdmin : listWithdrawAdminReducer,
    updateDepositStatus : depositAdminReducer,
    addMatch : addMatchAdminReducer,
    listBetMatchTeam : listBettingMatchesByDetailsReducer,
    listAllMatches : listAllMatchesReducers,
    updateToss : updateTossWinner,
    updateMatchStatus :updateMatchStatus,
    updateWithdrawStatus : withdrawAdminReducer,
    listPayment : listPaymentReducer,
    updateBankDetails : updatePaymentReducer
});

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
