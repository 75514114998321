export const ADD_BET_REQUEST = "ADD_BET_REQUEST";
export const ADD_BET_SUCCESS = "ADD_BET_SUCCESS";
export const ADD_BET_FAILURE = "ADD_BET_FAILURE";

export const LIST_BET_REQUEST = "LIST_BET_REQUEST";
export const LIST_BET_SUCCESS = "LIST_BET_SUCCESS";
export const LIST_BET_FAILURE = "LIST_BET_FAILURE";

export const ADD_BET_RESET = "ADD_BET_RESET"

export const LIST_BET_TEAM_REQUEST = "LIST_BET_TEAM_REQUEST";
export const LIST_BET_TEAM_SUCCESS = "LIST_BET_TEAM_SUCCESS";
export const LIST_BET_TEAM_FAILURE = "LIST_BET_TEAM_FAILURE";
