export const NEW_DEPOSIT_REQUEST = "NEW_DEPOSIT_REQUEST";
export const NEW_DEPOSIT_SUCCESS = "NEW_DEPOSIT_SUCCESS";
export const NEW_DEPOSIT_FAIL = "NEW_DEPOSIT_FAIL";
export const NEW_DEPOSIT_RESET = "NEW_DEPOSIT_RESET";

export const LIST_DEPOSIT_REQUEST = "LIST_DEPOSIT_REQUEST"
export const LIST_DEPOSIT_SUCCESS = "LIST_DEPOSIT_SUCCESS"
export const LIST_DEPOSIT_FAIL = "LIST_DEPOSIT_FAIL"
export const LIST_DEPOSIT_RESET = "LIST_DEPOSIT_FAIL"

export const LIST_PAYMENT_REQUEST = "LIST_PAYMENT_REQUEST"
export const LIST_PAYMENT_SUCCESS = "LIST_PAYMENT_SUCCESS"
export const LIST_PAYMENT_FAIL = "LIST_PAYMENT_FAIL"
export const LIST_PAYMENT_RESET = "LIST_PAYMENT_RESET"