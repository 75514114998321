import AdminInfoCard from "../components/Admin/Dashboard/AdminInfoCard";
import FormCard from "../components/Form/FormCard";
import TextInput from "../components/Form/TextInput";
import SelectField from "../components/Form/SelectField";
import {teams} from "../utils/constant";
import {useEffect, useState} from "react";
import DateTime from "../components/Form/DateTime";
import Button from "../components/Form/Button";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {addMatch, addMatchAdmin} from "../actions/adminAction";
import {ADD_MATCH_ADMIN_RESET} from "../constants/adminConstants";
import FileInput from "../components/Form/FileInput";
import {toast} from "react-toastify";
import {max} from "react-table/src/aggregations";

export const ScheduleMatch = () =>{
    const [teamA,setTeamA] = useState('');
    const [teamB,setTeamB] = useState('');
    const [venue,setVenue] = useState('');
    const [matchNo,setMatchNo] = useState('');
    const [date,setDate] = useState('');
    const [time,setTime] = useState('')
    const [teamALogo,setTeamALogo] = useState('');
    const [teamBLogo,setTeamBLogo] = useState('');
    const [minAmount,setMinAmount] = useState(100);
    const [maxAmount,setMaxAmount] = useState(100);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);

    const {success} = useSelector(state=>state.addMatch);
    const onHandleSubmit = async (e) =>{
        e.preventDefault();
        if(minAmount===0 || maxAmount === 0){
            toast.error("Min or Max Amount can't be zero");
            return;
        }
        const form = new FormData();
        form.append('teamALogo',teamALogo);
        form.append('teamBLogo', teamBLogo);
        form.append('teamA', teamA);
        form.append('teamB', teamB);
        form.append('matchNo', matchNo);
        form.append('date', date);
        form.append('venue', venue);
        form.append('minAmount', minAmount);
        form.append('maxAmount', maxAmount);
        await dispatch(addMatchAdmin(token,form))

    }

    useEffect(() => {
        if(!adminUser || !token || !isAuthenticated){
            navigate('/adminLogin')
        }
    }, [dispatch]);

    useEffect(()=>{
        if(success){
            console.log('Entered Successfully')
            dispatch({type : ADD_MATCH_ADMIN_RESET})
            navigate('/admin/listMatches')
            setTeamA('')
            setTeamB('')
            setVenue('')
            setMatchNo('')
            setDate('')
            setTime('')
            setMinAmount(100);
            setMaxAmount(100);
        }
    },[success])
    return (
        <div className={'flex flex-col gap-10 p-4 mt-5'}>
            <h1 className={'text-3xl'}>Schedule Match</h1>
            <AdminInfoCard/>
            <FormCard className={'flex flex-col w-full gap-10 px-24'} onSubmit={onHandleSubmit}>
                <div className={'flex flex-row w-full'}>
                    <TextInput className={'w-full lg:w-1/2'} placeholder={'Match No'} value={matchNo} onChange={(e) => {
                        setMatchNo(e.target.value)
                    }}></TextInput>
                </div>
                <div className={'flex flex-col gap-10 lg:flex-row lg:gap-2 w-full'}>
                    <TextInput placeholder={'Select Team A'} className={'w-full rounded-md'}
                               value={teamA}
                               onChange={(e) => {
                                   setTeamA(e.target.value)
                               }}
                    />
                    <TextInput placeholder={'Select Team B'} className={'w-full rounded-md'}
                               value={teamB}
                               onChange={(e) => {
                                   setTeamB(e.target.value)
                               }}
                    />

                </div>
                <div className={'flex flex-col gap-10 lg:flex-row lg:gap-2 w-full'}>
                    <TextInput input={"number"} placeholder={'Min Amount'} className={'w-full rounded-md'}
                               value={minAmount}
                               onChange={(e) => {
                                   setMinAmount(e.target.value)
                               }}
                    />
                    <TextInput input={"number"} placeholder={'Max Amount'} className={'w-full rounded-md'}
                               value={maxAmount}
                               onChange={(e) => {
                                   setMaxAmount(e.target.value)
                               }}
                    />

                </div>
                <div className={'flex flex-col gap-10 lg:flex-row lg:gap-2 w-full'}>
                    <FileInput label={'select team A logo'} onChange={setTeamALogo}/>
                    <FileInput label={'select team B logo'} onChange={setTeamBLogo}/>
                </div>
                <div className={'flex flex-row w-full'}>
                    <TextInput className={'w-full lg:w-1/2'} placeholder={'Venue'} value={venue} onChange={(e) => {
                        setVenue(e.target.value)
                    }}></TextInput>
                </div>
                <div className={'flex flex-col justify-center-center gap-10 w-full lg:w-1/4'}>
                    <DateTime type={'datetime-local'} labelText={'Date:'} value={date} onChange={(e) => {
                        setDate(e.target.value)
                    }}/>
                </div>
                <div className={'w-full flex items-center justify-center'}>
                    <Button className={'text-white px-10 py-4'} type={'submit'}>
                        Add Match
                    </Button>
                </div>
            </FormCard>
        </div>
    )
}
