import {useState} from "react";
import classnames from "classnames";

const SelectField = ({options,title,className,selectedValue,setSelectedValue}) => {
    const classNames = classnames(className,'relative')
    const [isOpened,setIsOpened] = useState(false);
    const renderedList =options.map((value,index)=>{
        return (
            <li key={index} className={`${value === selectedValue?'hidden':''}`}>
                <button
                   className="block px-4 py-2 hover:bg-blue-300" onClick={()=>{
                    setSelectedValue(value)
                    setIsOpened(false)
                }}>{value}</button>
            </li>
    )
    })
    return (
        <div className={classNames}>
            <button
                className="flex flex-row w-full justify-between text-black ring-1 ring-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center "
                type="button" onClick={()=>{
              setIsOpened(!isOpened)
          }}>{selectedValue?selectedValue:title}<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="m1 1 4 4 4-4"/>
          </svg>
          </button>
          <div
               className={`w-full absolute top-10 z-20 ${(!isOpened)?'hidden':''} bg-white rounded-lg shadow  `}>
              <ul className="py-2 text-sm text-gray-700 w-full " aria-labelledby="dropdownDefaultButton">
                  {renderedList}
              </ul>
          </div>
      </div>
  )
}
export default SelectField
