import Layout from "../components/Dashboard/Layout";
import React, {useEffect} from "react";
import Table from "../components/Form/Table";
import SubNavbar from "../components/SubNavbar";
import {useDispatch, useSelector} from "react-redux";
import {getDeposites} from "../actions/depositAction";
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../components/Form/Loading";
import {BASE_SCREENSHOT_IMAGE_URL} from "../utils/constant";

const DepositeHistoryPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {listDeposits,loading} = useSelector(state=>state.listDeposit);
    const {token,user,isAuthenticated} = useSelector(state=> state.user);

    const extractDate = (dateStr) => {
        const dateObj = new Date(dateStr);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return dateObj.toLocaleDateString('en-US', options); // Format the date
    };

    useEffect(() => {
        if(!user || !isAuthenticated || !token){
            navigate('/login')
        }
        dispatch(getDeposites(token))
    }, []);


    const navbarElement = [
        {
            name: 'Deposit',
            path: '/deposite'
        },
        {
            name: 'Deposit History',
            path: '/depositeHistory'
        },
        {
            name: 'Wallet History',
            path: '/walletHistory'
        }
    ]

    const renderedData = listDeposits ?  listDeposits.map((data,index)=>{
        return (
            {
                srno : index+1,
                transactionId :data.transactionId,
                screenshot : <a target="_blank" rel="noopener noreferrer" href={BASE_SCREENSHOT_IMAGE_URL+data.screenshot}>{data.screenshot}</a>,
                depositAmount:data.amount,
                status : data.status,
                date : extractDate(data.createdAt)
            }
        )
    }) : []

    const columns = [
        { Header: 'Srno', accessor: 'srno' },
        { Header: 'TransactionId', accessor: 'transactionId' },
        { Header: 'Screenshot', accessor: 'screenshot' },
        { Header: 'DepositAmount', accessor: 'depositAmount' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Date', accessor: 'date' },
    ]
    return (
        (loading) ? <LoadingScreen/>:
            <Layout className={'flex flex-col gap-10 mb-10'}>
            <SubNavbar navbarElement={navbarElement} className={'ml-2 lg:ml-10 flex flex-row gap-7'} />
            <div className={'ml-10 flex flex-row items-center gap-5'}>
                <div className={'w-2 h-2 rounded bg-red-600'}></div>
                <text className={'text-lg font-bold'}>Deposit History</text>
                <div className={'w-1/12 h-1 bg-red-600'}></div>
            </div>
            <Table className={'w-2/3 mx-auto rounded-md border-[#FFFFFF] shadow drop-shadow-md'} data={renderedData}
                columns={columns} />
        </Layout>
    )
}
export default DepositeHistoryPage
