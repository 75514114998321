import Banner from "../components/Banner";
import Advertise from "../components/Advertise";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import FormCard from "../components/Form/FormCard";
import TextInput from "../components/Form/TextInput";
import {useEffect, useState} from "react";
import Button from "../components/Form/Button";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loginAdminUser} from "../actions/adminAction";
const AdminLoginPage =  () =>{
    const [isFocused, setIsFocused] = useState(false);

    const [username,setUsername] = useState('')
    const [password,setPassword]  = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, isAuthenticated, error, adminUser, adminToken } = useSelector((state) => state.adminUser);

    useEffect(() => {
        if(isAuthenticated && adminUser) {
            navigate('/admin/listDeposit')
        }
    }, [isAuthenticated,adminUser,dispatch]);

    useEffect(()=>{
        if(error){
            console.log(error)
        }
    },[error])

    const onHandleSubmit = async (e) =>{
        e.preventDefault();
        if(username && password){
            await dispatch(loginAdminUser(username,password));
        }
    }

    return (
        <div className={'flex flex-col items-center gap-10 justify-center mb-10'}>
            <Banner className={'h-56 lg:h-96 relative'} bannerImage={require('../assests/images/banner.png')}>
              <Advertise className={'absolute bottom-4 md:bottom-5 md:right-10 md:w-1/2 lg:w-1/3 sm:gap-2 lg:gap-10 text-center p-4 flex flex-col '} title = {'Indian Premier League'} subTitle ={'Use your free bet on champion league match and win incredible prizes'}  action = {()=>{
                  console.log('Clicked')
              }}/>
            </Banner>
            <div className={'flex flex-col items-center gap-10'}>
                <Title displayText={'LOGIN TO ADMIN'}/>
                {/*<SubTitle displayText={'Gaming Helper is the most advanced sports trading & affialiate platform and highest stakes across multiple bookmakers and exchanges.'}/>*/}
            </div>
            <FormCard className={'w-[95%] sm:w-1/2 xl:w-1/3 flex flex-col gap-7 justify-center items-center'} onSubmit={onHandleSubmit}>
                <TextInput
                    className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}
                    type = "text"
                    placeholder={'Enter Your Register Phone Number'}
                    value = {username}
                    onChange = {(e)=>{setUsername(e.target.value)}}
                    onFocus = {() => setIsFocused(true)}
                    onBlur = {() => setIsFocused(false)}
                />
                <TextInput
                    className={`${isFocused ? 'border border-blue-50' : 'border'} w-full`}
                    type = "password"
                    placeholder={'Enter Your Password'}
                    value = {password}
                    onChange = {(e)=>{ setPassword(e.target.value) }}
                    onFocus = {() => setIsFocused(true)}
                    onBlur = {() => setIsFocused(false)}
                />
                <Button className={'rounded-lg p-4 px-10'} type={'submit'}>
                    LOGIN
                </Button>
            </FormCard>
        </div>
    )
}
export default AdminLoginPage
