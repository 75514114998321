export const LOGIN_ADMIN_USER_REQUEST = "LOGIN_ADMIN_USER_REQUEST";
export const LOGIN_ADMIN_USER_SUCCESS = "LOGIN_ADMIN_USER_SUCCESS";
export const LOGIN_ADMIN_USER_FAIL = "LOGIN_ADMIN_USER_FAIL";
export const ADMIN_USER_LOGOUT  = "ADMIN_USER_LOGOUT"
export const GET_USER_STATS_REQUEST = "GET_USER_STATS_REQUEST"
export const GET_USER_STATS_SUCCESS = "GET_USER_STATS_SUCCESS"
export const GET_USER_STATS_FAIL = "GET_USER_STATS_FAIL"

export const LIST_DEPOSIT_ADMIN_REQUEST = "LIST_DEPOSIT_ADMIN_REQUEST"
export const LIST_DEPOSIT_ADMIN_SUCCESS = "LIST_DEPOSIT_ADMIN_SUCCESS"
export const LIST_DEPOSIT_ADMIN_FAIL = "LIST_DEPOSIT_ADMIN_FAIL"
export const LIST_DEPOSIT_ADMIN_RESET = "LIST_DEPOSIT_ADMIN_RESET"

export const LIST_WITHDRAW_ADMIN_REQUEST = "LIST_WITHDRAW_ADMIN_REQUEST"
export const LIST_WITHDRAW_ADMIN_SUCCESS = "LIST_WITHDRAW_ADMIN_SUCCESS"
export const LIST_WITHDRAW_ADMIN_FAIL = "LIST_WITHDRAW_ADMIN_FAIL"
export const LIST_WITHDRAW_ADMIN_RESET = "LIST_WITHDRAW_ADMIN_RESET"

export const UPDATE_DEPOSIT_ADMIN_RESET = "UPDATE_DEPOSIT_ADMIN_RESET"
export const UPDATE_DEPOSIT_ADMIN_FAIL = "UPDATE_DEPOSIT_ADMIN_FAIL"
export const UPDATE_DEPOSIT_ADMIN_SUCCESS = "UPDATE_DEPOSIT_ADMIN_SUCCESS"
export const UPDATE_DEPOSIT_ADMIN_REQUEST = "UPDATE_DEPOSIT_ADMIN_REQUEST"

export const ADD_MATCH_ADMIN_RESET = "ADD_MATCH_ADMIN_RESET"
export const ADD_MATCH_ADMIN_FAIL = "ADD_MATCH_ADMIN_FAIL"
export const ADD_MATCH_ADMIN_SUCCESS = "ADD_MATCH_ADMIN_SUCCESS"
export const ADD_MATCH_ADMIN_REQUEST = "ADD_MATCH_ADMIN_REQUEST"
export const LIST_MATCHES_ADMIN_REQUEST = "LIST_MATCHES_ADMIN_REQUEST"
export const LIST_MATCHES_ADMIN_SUCCESS = "LIST_MATCHES_ADMIN_SUCCESS"
export const LIST_MATCHES_ADMIN_FAIL = "LIST_MATCHES_ADMIN_FAIL"
export const LIST_MATCHES_ADMIN_RESET = "LIST_MATCHES_ADMIN_RESET"

export const ADD_TOSS_WINNER_ADMIN_RESET = "ADD_TOSS_WINNER_ADMIN_RESET"
export const ADD_TOSS_WINNER_ADMIN_SUCCESS = "ADD_TOSS_WINNER_ADMIN_SUCCESS"
export const ADD_TOSS_WINNER_ADMIN_FAIL = "ADD_TOSS_WINNER_ADMIN_FAIL"
export const ADD_TOSS_WINNER_ADMIN_REQUEST = "ADD_TOSS_WINNER_ADMIN_REQUEST"

export const UPDATE_MATCH_STATUS_ADMIN_RESET = "UPDATE_MATCH_STATUS_ADMIN_RESET"
export const UPDATE_MATCH_STATUS_ADMIN_REQUEST = "UPDATE_MATCH_STATUS_ADMIN_REQUEST"
export const UPDATE_MATCH_STATUS_ADMIN_FAIL = "UPDATE_MATCH_STATUS_ADMIN_FAIL"
export const UPDATE_MATCH_STATUS_ADMIN_SUCCESS = "UPDATE_MATCH_STATUS_ADMIN_SUCCESS"

export const UPDATE_WITHDRAW_ADMIN_RESET = "UPDATE_WITHDRAW_ADMIN_RESET"
export const UPDATE_WITHDRAW_ADMIN_FAIL = "UPDATE_WITHDRAW_ADMIN_FAIL"
export const UPDATE_WITHDRAW_ADMIN_SUCCESS = "UPDATE_WITHDRAW_ADMIN_SUCCESS"
export const UPDATE_WITHDRAW_ADMIN_REQUEST = "UPDATE_WITHDRAW_ADMIN_REQUEST"


export const UPDATE_LIST_PAYMENT_RESET = "UPDATE_LIST_PAYMENT_RESET"
export const UPDATE_LIST_PAYMENT_FAIL = "UPDATE_LIST_PAYMENT_FAIL"
export const UPDATE_LIST_PAYMENT_SUCCESS = "UPDATE_LIST_PAYMENT_SUCCESS"
export const UPDATE_LIST_PAYMENT_REQUEST = "UPDATE_LIST_PAYMENT_REQUEST"
