import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import Button from "../../Form/Button";
import {ADMIN_USER_LOGOUT} from "../../../constants/adminConstants";
import {useDispatch} from "react-redux";

const AdminNavbar = ({navbarElements}) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const renderNavbarElement = navbarElements.map(({name,path},index)=>{
        return (
            <li className="text-gray-300 hover:text-white py-2 px-2 md:px-0">
                <Link to={path}>{name}</Link>
            </li>
        )
    })
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-gray-800">
            <div className="container mx-auto px-4 py-2 md:flex md:items-center md:justify-between">
                <div className="flex items-center justify-between">
                    <a href="/" className="text-white text-xl font-bold">Admin Panel</a>
                    <button onClick={toggleMenu} className="text-gray-300 hover:text-white md:hidden">
                        <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        </svg>
                    </button>
                </div>

                <ul className={`md:flex md:items-center md:space-x-8 ${isOpen ? 'block' : 'hidden'} md:block`}>
                    {renderNavbarElement}
                    <Button className={'px-4 py-1'} onClick={()=>{
                        dispatch({type : ADMIN_USER_LOGOUT})
                        navigate('/adminLogin')
                    }}>
                        Log out
                    </Button>
                </ul>
            </div>
        </nav>
    );
};

export default AdminNavbar;
