import {
    LIST_DEPOSIT_FAIL,
    LIST_DEPOSIT_REQUEST, LIST_DEPOSIT_RESET, LIST_DEPOSIT_SUCCESS,
    LIST_PAYMENT_FAIL,
    LIST_PAYMENT_REQUEST,
    LIST_PAYMENT_SUCCESS,
    NEW_DEPOSIT_FAIL,
    NEW_DEPOSIT_REQUEST,
    NEW_DEPOSIT_RESET,
    NEW_DEPOSIT_SUCCESS
} from "../constants/depositConstants";

export const newDepositReducer =  (state = { deposit: {}}, { type, payload }) => {
    switch (type){
        case NEW_DEPOSIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_DEPOSIT_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                deposit: payload.deposit,
            };
        case NEW_DEPOSIT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_DEPOSIT_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}

export const listDepositReducer = (state = { listDeposits: []}, { type, payload }) => {
    switch (type) {
        case LIST_DEPOSIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LIST_DEPOSIT_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                listDeposits: payload.deposits,
            };
        case LIST_DEPOSIT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case LIST_DEPOSIT_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}

export const listPaymentReducer = (state = {listPayment : {} }, {type,payload}) => {
    switch (type) {
        case LIST_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LIST_PAYMENT_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                listPayment: payload.data,
            };
        case LIST_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case LIST_DEPOSIT_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}