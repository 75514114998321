import classnames from "classnames";

const SubTitle = ({displayText,className}) => {
    const classNames = classnames(className,'text-lg w-2/3 text-center text-[#383838]')
    return (
        <text className={classNames}>
            {displayText}
        </text>
    )
}
export default SubTitle
