import React, {useEffect} from 'react';
import DashboardProfile from '../../assests/images/dashboardProfile.png';
import {useSelector} from "react-redux";
import Banner from "../Banner";
import Button from "../Form/Button";
import {useNavigate} from "react-router-dom";

export default function Header() {
    const {user,token,isAuthenticated} = useSelector(state => state.user);
    const navigate = useNavigate()
    return (
        <Banner className={"w-full h-96 relative"} bannerImage={require('../../assests/images/backgroundDashboard.png')}>
            <div
                className={'absolute w-full top-4 lg:top-10 px-10 flex flex-col text-white justify-center items-center gap-5'}>
                <div className={'w-full flex  flex-row justify-center items-center gap-4 lg:gap-10'}>
                    <img className={'h-32 w-32 lg:h-full lg:w-96'} src={DashboardProfile} alt="Layout Profile"/>
                    <div className={'flex flex-col w-full font-semibold'}>
                        <h1 className={'text-2xl lg:text-2xl'}>Welcome</h1>
                        <h1 className={'text-3xl lg:text-5xl'}>{(user) ? user.userName : ""}</h1>
                        <h1 className={'flex lg:hidden text-red-500'}>
                            Wallet Coins : {(user) ? user.walletCoins : ""}
                        </h1>
                    </div>
                    <div className={'hidden lg:flex flex-col sm:flex-row w-full gap-4'}>
                        <Button className={'w-full p-4'}>
                            Wallet Coins : {(user) ? user.walletCoins : ""}
                        </Button>
                        <Button className={'w-full p-4 px-10  '} onClick={() => {
                            navigate('/deposite')
                        }}>
                            Deposit
                        </Button>
                        <Button className={'w-full p-4 px-10   bg-transparent border-2'} onClick={() => {
                            navigate('/withdraw')
                        }}>
                            Withdraw
                        </Button>
                    </div>
                </div>
                <div className={'lg:hidden flex flex-row sm:flex-row w-full gap-4'}>

                    <Button className={'w-full p-4 text-md rounded-md'} onClick={() => {
                        navigate('/deposite')
                    }}>
                        Deposit
                    </Button>
                    <Button className={'w-full p-4  bg-transparent border-2'} onClick={() => {
                        navigate('/withdraw')
                    }}>
                        Withdraw
                    </Button>
                </div>
                <div className={'w-full py-5 sm:py-2 xl:w-2/3 md:py-3 border-t'}>
                    <div className={'w-full flex flex-row'} style={{background: 'rgba(193,9,48,50%)'}}>
                        <Button className={'w-full p-4 sm:px-10 bg-transparent font-semibold'} onClick={() => {
                            navigate('/dashboard')
                        }}>
                            Live Bet
                        </Button>
                        <Button className={'w-full p-4 sm:px-10 bg-transparent font-semibold'} onClick={() => {
                            navigate('/betList')
                        }}>
                            Bet List
                        </Button>
                        <Button className={'w-full p-4 sm:px-10 bg-transparent font-semibold'} onClick={() => {
                            navigate('/walletHistory')
                        }}>
                            My Wallet
                        </Button>
                        {/*<Button className={'w-full p-4 sm:px-10 bg-transparent font-semibold'} onClick={() => {*/}
                        {/*    navigate('/profile/edit')*/}
                        {/*}}>*/}
                        {/*    My Profile*/}
                        {/*</Button>*/}

                    </div>
                </div>
            </div>

        </Banner>
    );
}
