import {
    LIST_WITHDRAW_FAIL, LIST_WITHDRAW_REQUEST, LIST_WITHDRAW_RESET, LIST_WITHDRAW_SUCCESS,
    NEW_WITHDRAW_FAIL,
    NEW_WITHDRAW_REQUEST,
    NEW_WITHDRAW_RESET,
    NEW_WITHDRAW_SUCCESS
} from "../constants/withdrawConstants";
import {
    LIST_DEPOSIT_FAIL,
    LIST_DEPOSIT_REQUEST,
    LIST_DEPOSIT_RESET,
    LIST_DEPOSIT_SUCCESS
} from "../constants/depositConstants";

export const newWithdrawReducer =  (state = { withdraw: {}}, { type, payload }) => {
    switch (type){
        case NEW_WITHDRAW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_WITHDRAW_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                withdraw: payload.deposit,
            };
        case NEW_WITHDRAW_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_WITHDRAW_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
export const listWithdrawReducer = (state = { listWithdraws: []}, { type, payload }) => {
    switch (type) {
        case LIST_WITHDRAW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LIST_WITHDRAW_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                listWithdraws: payload.withdraws,
            };
        case LIST_WITHDRAW_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case LIST_WITHDRAW_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
