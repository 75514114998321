import { ADD_MATCH_REQUEST, ADD_MATCH_SUCCESS, ADD_MATCH_FAILURE } from "../constants/matchConstants.js";
import axios from "../utils/axiosConfig.js";
import {
    LIST_MATCHES_FAIL,
    LIST_MATCHES_REQUEST,
    LIST_MATCHES_SUCCESS
} from "../constants/matchConstants";

export const addMatch = (teamA, teamB, date, time, venue) => async (dispatch) => {
    try {
        dispatch({ type: ADD_MATCH_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.post(
            '/api/v1/addMatch',
            { teamA, teamB, date, time, venue },
            config
        );

        dispatch({
            type: ADD_MATCH_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_MATCH_FAILURE,
            payload: error.message,
        });
    }
}
export const getTodaysMatch = () => async (dispatch) => {
    try {
        dispatch({ type: LIST_MATCHES_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.get(
            '/api/v1/getLiveMatches',
            {},
            config
        );

        dispatch({
            type: LIST_MATCHES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_MATCHES_FAIL,
            payload: error.message,
        });
    }
}
