import React, {useEffect} from 'react'
import Layout from '../components/Dashboard/Layout'
import BetMatch from '../components/Dashboard/BetMatch'
import LiveBet from '../components/Dashboard/LiveBet'
import MatchCard from '../components/Dashboard/MatchCard'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const {user,token,isAuthenticated} = useSelector(state => state.user);
    const navigate = useNavigate()

    useEffect(() => {
        if(!user || !token || !isAuthenticated){
            navigate('/login')
        }
    }, [user,token,isAuthenticated]);

    return (
        <BetMatch/>
    )
}

export default Dashboard
