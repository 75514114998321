import {
    LOAD_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_WALLET_HISTORY_FAIL,
    LOAD_WALLET_HISTORY_REQUEST,
    LOAD_WALLET_HISTORY_SUCCESS,
    LOGIN_USER_FAIL,
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS
} from "../constants/userConstant";
import axios from "../utils/axiosConfig";

export const loginUser = (userName, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_USER_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.post(
            '/api/v1/login',
            { userName, password },
            config
        );

        dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LOGIN_USER_FAIL,
            payload: error,
        });
    }
}

// Register User
export const registerUser = (userName, password) => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_USER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.post(
            '/api/v1/register',
            { userName, password },
            config
        );

        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error.message,
        });
    }
};

export const loadUser = (token) => async (dispatch) =>{
    try {

        dispatch({ type: LOAD_USER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const {data} = await axios.get(
            '/api/v1/me',
            config
        );
        console.log(data)
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data,
        });

    } catch (error) {
        console.log(error.message)
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.message,
        });
    }
}

export const loadWalletHistory = (token)=> async (dispatch) =>{
    try {

        dispatch({ type: LOAD_WALLET_HISTORY_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const {data} = await axios.get(
            '/api/v1/listWalletHistory',
            config
        );
        console.log(data)
        dispatch({
            type: LOAD_WALLET_HISTORY_SUCCESS,
            payload: data,
        });

    } catch (error) {
        console.log(error.message)
        dispatch({
            type: LOAD_WALLET_HISTORY_FAIL,
            payload: error.message,
        });
    }
}
