import {
    CLEAR_ERRORS,
    LOAD_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_WALLET_HISTORY_FAIL,
    LOAD_WALLET_HISTORY_REQUEST, LOAD_WALLET_HISTORY_SUCCESS,
    LOGIN_USER_FAIL,
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    LOGOUT_USER_SUCCESS,
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS
} from "../constants/userConstant";

export const userReducer = (state = { user: {} }, { type, payload }) => {

    switch (type) {
        case LOGIN_USER_REQUEST:
        case REGISTER_USER_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };
        case LOGIN_USER_SUCCESS:
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: payload.data,
                token : payload.userToken
            };
        case LOGOUT_USER_SUCCESS:
            return {
                loading: false,
                user: null,
                isAuthenticated: false,
            };
        case LOGIN_USER_FAIL:
        case REGISTER_USER_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: payload,
            };
        case LOGOUT_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case LOAD_USER_REQUEST:
            return {
                ...state,
                loading: false,
            };
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: payload.data,
            };
        case LOAD_USER_FAIL:
            return {
                ...state,
                loading: false,
                user: null,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}
export const listWalletReducer = (state = { listWalletHistory: [] }, { type, payload }) => {
    switch (type){
        case LOAD_WALLET_HISTORY_SUCCESS:
            return {
                ...state,
                loading : false,
                listWalletHistory:  payload.withdraws
            }
        case LOAD_WALLET_HISTORY_FAIL:
            return {
                ...state,
                loading : false,
            }
        case LOAD_WALLET_HISTORY_REQUEST:
            return {
                ...state,
                loading : true,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}
