import {
    ADD_BET_REQUEST,
    ADD_BET_SUCCESS,
    ADD_BET_FAILURE,
    LIST_BET_REQUEST,
    LIST_BET_SUCCESS,
    LIST_BET_FAILURE,
    LIST_BET_RESET,
    ADD_BET_RESET,
    LIST_BET_TEAM_REQUEST,
    LIST_BET_TEAM_SUCCESS,
    LIST_BET_TEAM_FAILURE
} from "../constants/betConstants.js";
import {LIST_MATCHES_FAIL, LIST_MATCHES_REQUEST, LIST_MATCHES_SUCCESS} from "../constants/matchConstants";
import {NEW_WITHDRAW_RESET} from "../constants/withdrawConstants";

export const betReducer = (state = { bet: {}, loading: false, error: null }, { type, payload }) => {
    switch (type) {
        case ADD_BET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_BET_SUCCESS:
            return {
                ...state,
                bet: payload.data,
                success : payload.success,
                loading: false
            };
        case ADD_BET_FAILURE:
            return {
                ...state,
                loading: false,
                success : payload.success,
                error: payload
            };
        case ADD_BET_RESET:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
    }
}

export const listBettingMatchesReducer =  (state = { betMatches: null, loading: false, error: null }, { type, payload }) => {
    switch(type) {
        case LIST_BET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LIST_BET_SUCCESS:
            return {
                ...state,
                betMatches: payload.betMatches,
                loading: false
            };
        case LIST_BET_FAILURE:
            return {
                ...state,
                loading: true,
                error: null
            };

        default:
            return state;
    }
}
export const listBettingMatchesByDetailsReducer =  (state = { betMatchesByTeam: null, loading: false, error: null }, { type, payload }) => {
    switch(type) {
        case LIST_BET_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LIST_BET_TEAM_SUCCESS:
            return {
                ...state,
                betMatchesByTeam: payload.betMatches,
                loading: false
            };
        case LIST_BET_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: null
            };

        default:
            return state;
    }
}
