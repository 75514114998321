import classnames from 'classnames';
const DateTime = ({className,labelText,...props}) => {
    const classNames = classnames(className,'p-4 rounded drop-shadow-md')
    const randomId = Math.random().toString(36).slice(2, 7);
    return (
        <>
            <label htmlFor={randomId}>{labelText}</label>
            <input className={classNames} id={randomId} {...props}/>
        </>
    )
}
export default DateTime
