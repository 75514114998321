import AdminInfoCard from "../components/Admin/Dashboard/AdminInfoCard";
import FormCard from "../components/Form/FormCard";
import TextInput from "../components/Form/TextInput";
import SelectField from "../components/Form/SelectField";
import {BASE_SCREENSHOT_IMAGE_URL, teams} from "../utils/constant";
import {useEffect, useState} from "react";
import DateTime from "../components/Form/DateTime";
import Button from "../components/Form/Button";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {addMatch, addMatchAdmin, updateBankDetails} from "../actions/adminAction";
import {ADD_MATCH_ADMIN_RESET, UPDATE_LIST_PAYMENT_RESET} from "../constants/adminConstants";
import FileInput from "../components/Form/FileInput";
import {toast} from "react-toastify";
import {max} from "react-table/src/aggregations";
import { getPaymentList } from "../actions/depositAction";

export const BankDetails = () =>{

    const [paytm,setPaytm] = useState('');
    const [phonepay,setPhonePay] = useState('');
    const [googlepay,setGooglePay] = useState('');
    const [upiId,setUpiId] = useState('');
    const [qrscreenshot,setQrScreenshot] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);
    const {listPayment} = useSelector(state =>state.listPayment)
    const {success} = useSelector(state=>state.updateBankDetails);
    
    const onHandleSubmit = async (e) =>{
        e.preventDefault();
        console.log(qrscreenshot)
        const form = new FormData();
        form.append('paytm',paytm);
        form.append('phonepay', phonepay);
        form.append('googlepay', googlepay);
        form.append('upiId', upiId);
        form.append('qrscreenshot', qrscreenshot);
        await dispatch(updateBankDetails(token,form))

    }
    const onFetchPayment = async () =>{
        await dispatch(getPaymentList())
    }
    useEffect(() => {
        if(!adminUser || !token || !isAuthenticated){
            navigate('/adminLogin')
        }
        onFetchPayment()
    }, [dispatch]);

    useEffect(()=>{
        if(listPayment){
            setPaytm(listPayment.paytm?listPayment.paytm:"")
            setUpiId(listPayment.upiId?listPayment.upiId:"")
            setPhonePay(listPayment.phonepay?listPayment.phonepay:"")
            setGooglePay(listPayment.googlepay?listPayment.googlepay:"")
            setQrScreenshot(listPayment.qrscreenshot?listPayment.qrscreenshot:"")
        }
    },[listPayment])

    useEffect(()=>{
        if(success){
            toast.success('Successfully Uploaded Bank Details')
            dispatch({type : UPDATE_LIST_PAYMENT_RESET})
        }
    },[success])
    return (
        <div className={'flex flex-col gap-10 p-4 mt-5'}>
            <h1 className={'text-3xl'}>Bank Details</h1>
            <AdminInfoCard/>
            <FormCard className={'flex flex-col w-full gap-10 px-24'} onSubmit={onHandleSubmit}>
                <div className={'flex flex-col gap-10 lg:flex-row w-full lg:gap-2'}>
                    <TextInput className={'w-full rounded-md'} placeholder={'Paytm Details'} value={paytm} onChange={(e) => {
                        setPaytm(e.target.value)
                    }}>

                    </TextInput>
                      <TextInput placeholder={'Phonepay'} className={'w-full rounded-md'}
                               value={phonepay}
                               onChange={(e) => {
                                   setPhonePay(e.target.value)
                               }}
                    />
                </div>
                <div className={'flex flex-col gap-10 lg:flex-row w-full lg:gap-2'}>
                    <TextInput placeholder={'Google Pay'} className={'w-full rounded-md'}
                               value={googlepay}
                               onChange={(e) => {
                                   setGooglePay(e.target.value)
                               }}
                    />

                    <TextInput placeholder={'Upi Id'} className={'w-full rounded-md'}
                               value={upiId}
                               onChange={(e) => {
                                setUpiId(e.target.value)
                               }}
                    />
                </div>
                <div className={'flex flex-col gap-10 lg:flex-row lg:gap-2 w-full'}>
                    <FileInput label={'Qr Code'} onChange={setQrScreenshot}/>
                </div>
                <div className={'w-full flex items-center justify-center'}>
                    <Button className={'text-white px-10 py-4'} type={'submit'}>
                        Update Bank Details
                    </Button>
                </div>
            </FormCard>
        </div>
    )
}
