import classnames from "classnames";

const Title = ({displayText,className}) =>{
    const classNames = classnames(className,'font-extrabold text-4xl lg:text-5xl text-center')
    return (
        <text className={classNames}>
            {displayText}
        </text>
    )
}
export default Title
