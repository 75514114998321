import {
    ADD_MATCH_REQUEST,
    ADD_MATCH_SUCCESS,
    ADD_MATCH_FAILURE,
    LIST_MATCHES_REQUEST, LIST_MATCHES_FAIL
} from "../constants/matchConstants";
import {LIST_MATCHES_SUCCESS} from "../constants/matchConstants";

export const matchReducer = (state = { match: {}, loading: false, error: null }, { type, payload }) => {
    switch (type) {
        case ADD_MATCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_MATCH_SUCCESS:
            return {
                ...state,
                match: payload.data,
                loading: false
            };
        case ADD_MATCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload.error
            };
        default:
            return state;
    }
}
export const listMatchesReducer =  (state = { matches: null, loading: false, error: null }, { type, payload }) => {
    switch(type) {
        case LIST_MATCHES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LIST_MATCHES_SUCCESS:
            return {
                ...state,
                matches: payload.todaysMatches,
                loading: false
            };
        case LIST_MATCHES_FAIL:
            return {
                ...state,
                loading: true,
                error: null
            };
        default:
            return state;
    }
}
