import classnames from "classnames";
import {useState} from "react";

const Button = ({className,children,...props}) =>{
    const classNames =  classnames('bg-red-600 rounded-full font-bold sm:text-lg',className)
    const [isPressed, setIsPressed] = useState(false);
    const handleMouseDown = () => {
        setIsPressed(true);
    };

    const handleMouseUp = () => {
        setIsPressed(false);
    };
    return (
        <button
            className={classNames}
            style={{ opacity: isPressed ? 0.5 : 1 }} // Adjust opacity based on isPressed state
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            {...props}>
            {children}
        </button>
    )
}
export  default  Button
