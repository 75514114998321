import classnames from "classnames";

const RadioButton = ({labelText,className,randomId,...props}) =>{
    const classNames = classnames(className)
    const ranId = Math.random().toString(36).slice(2, 7);
    return(
        <div className={classNames}>
            <input  id={ranId} name={randomId} type={"radio"} {...props}/>
            <label htmlFor={ranId}>{labelText}</label>
        </div>
    )
}
export default RadioButton
