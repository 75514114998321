import {Link, Outlet, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import SubNavbar from "../../SubNavbar";
import Banner from "../../Banner";
import DashboardProfile from "../../../assests/images/dashboardProfile.png";
import Button from "../../Form/Button";
import {useDispatch, useSelector} from "react-redux";
import AdminNavbar from "./AdminNavbar";

const AdminLayout = () =>{
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);

    useEffect(() => {
        if(!isAuthenticated || !adminUser || !token){
            navigate('/adminLogin')
        }
    }, [adminUser,token,isAuthenticated]);

    const navElement = [
        {
            name : "Schedule Matches",
            path : '/admin/scheduleMatch'
        },
        {
            name : "Bank Details",
            path : '/admin/bankdetails'
        },
        {
            name : 'Deposit List',
            path : '/admin/listDeposit'
        },
        {
            name : 'Withdraw List',
            path : '/admin/listWithdraw'
        },
        {
            name : 'ListMatches',
            path : '/admin/listMatches'
        }
    ]

    const renderedNavbarElement = navElement.map((element,index) =>{
        return (
            <Link className={'text-2xl'} to={element.path}>
                {element.name}
            </Link>
        )
    })
    return (
        <div className={''}>
                {/*<Banner className={'w-full lg:w-1/5 absolute '}*/}
                {/*        bannerImage={require('../../../assests/images/adminNavbarBackground.png')}>*/}
                {/*<div className={'absolute w-full top-10 px-10 flex flex-col text-white justify-center items-center gap-5'}>*/}
                {/*    <div className={'w-full flex flex-col justify-center items-center gap-5'}>*/}
                {/*        <div className={'flex flex-row gap-5 items-center'}>*/}
                {/*            <img className={'w-32 h-32'} src={DashboardProfile} alt="Layout Profile"/>*/}
                {/*            <div className={'flex flex-col w-full font-semibold'}>*/}
                {/*                <h1 className={'text-3xl'}>{(adminUser) ? adminUser.firstName : ""}</h1>*/}
                {/*                <h1 className={'text-3xl'}>{(adminUser) ? adminUser.lastName : ""}</h1>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={'flex flex-row w-full gap-10'}>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className={'w-full py-5 border-t'}>*/}
                {/*        <div className={'w-full flex flex-row'}>*/}
                {/*            <ul className={'flex flex-col gap-10'}>*/}
                {/*                {renderedNavbarElement}*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*</Banner>*/}
                <AdminNavbar navbarElements={navElement}/>
                <Outlet/>
        </div>
    )
}
export default AdminLayout
