import AdminInfoCard from "../components/Admin/Dashboard/AdminInfoCard";
import MyTable from "../components/Form/Table";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Button from "../components/Form/Button";
import React, {useEffect, useState} from "react";
import {getDepositAdmin, getWithdrawAdmin} from "../actions/adminAction";
import {updateDepositStatus} from "../actions/depositAction";
import TextInput from "../components/Form/TextInput";
import SelectField from "../components/Form/SelectField";
import {updateWithdrawStatus} from "../actions/withdrawAction";
import {LIST_WITHDRAW_RESET} from "../constants/withdrawConstants";
import {BASE_SCREENSHOT_IMAGE_URL} from "../utils/constant";
import FileInput from "../components/Form/FileInput";

const ListWithDraw = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isAuthenticated, error, adminUser, token } = useSelector((state) => state.adminUser);
    const {listWithdrawsAdmin} = useSelector(state=>state.listWithdrawAdmin);
    const {success,withdraw} = useSelector(state=>state.updateWithdrawStatus);
    const [editWithdraws, setEditWithdraws] = useState({});
    const [transactionIds,setTransactionIds] = useState({})
    const [screenshootFiles,setScreenshotFiles] = useState({});
    const [editStatus,setEditStatus] = useState({})
    const handleEditChange = (withdrawId, value) => {
        setEditWithdraws({
            ...editWithdraws,
            [withdrawId]: value
        });
    }

    const handleEditTransactionChange = (withdrawId, value) =>{
        setTransactionIds({
            ...setTransactionIds,
            [withdrawId]: value
        })
    }

    const handleEditScreenshotChange = (withdrawId, value) =>{
        console.log('123')
        setScreenshotFiles(prevScreenshotFile => ({
            ...prevScreenshotFile, // Spread the previous state to maintain other dates
            [withdrawId]: value // Update the date for the given match ID
        }));
    }

    const handleEditStatusChange = (withdrawId, value) =>{
        setEditStatus({
            ...editStatus,
            [withdrawId]: value
        })
        console.log(withdrawId)

    }
    const fetchWithdraw = async ()=>{
        await dispatch(getWithdrawAdmin(token));
    }

    const onHandleAction = async (withdrawId) =>{
        const formData = new FormData();
        formData.append('withdrawId',withdrawId);
        formData.append('transactionId', transactionIds[withdrawId]);
        formData.append('approve', editStatus[withdrawId] === 'approve');
        formData.append('screenshot', screenshootFiles[withdrawId]);
        await dispatch(updateWithdrawStatus(token,formData));
    }
    useEffect(() => {
        if(!isAuthenticated || ! adminUser || !token){
            navigate('/adminLogin')
        }
        fetchWithdraw()
    }, [dispatch]);


    useEffect(() => {
        if(success) {
            dispatch({type : LIST_WITHDRAW_RESET})
            fetchWithdraw()
        }

    }, [success,dispatch]);

    const renderedData = listWithdrawsAdmin?listWithdrawsAdmin.map((value,index) =>{
        const isEditable = (editWithdraws[value._id])?editWithdraws[value._id] === true : false ;
        console.log(value)
        let details = ""
        if(value.upiId){
            details = <label>UPI ID :{value.upiId}</label>
        }else if(value.walletId){
            details = <label> Wallet ID : {value.walletId}</label>
        }else if(value.bankDetails){
            const {accountNumber,
                ifscCode,
                bankName,
                holderName} = value.bankDetails;
            details = <div className={'flex flex-col'}>
                <label>Account Number : {accountNumber}</label>
                <label>Ifsc Code : {ifscCode}</label>
                <label>Bank Name : {bankName}</label>
                <label>Holder Name : {holderName}</label>

            </div>
        }
        if(value.status === 'pending'){
            return {
                srno: index + 1,
                username: value.username,
                details: details,
                transactionId: (!isEditable) ? value.transactionId : <TextInput placeholder={'Enter Transaction ID'} value={(transactionIds[value._id])?transactionIds[value._id]:''} onChange={(e)=>{
                    handleEditTransactionChange(value._id,e.target.value)
                }} ></TextInput> ,
                withdrawAmount: value.amount,
                walletcoins: value.walletCoins,
                status: (!isEditable) ? value.status :<SelectField title={'Select The Status'} setSelectedValue={(select)=>{
                    handleEditStatusChange(value._id,select)
                }} options={['approve','reject']} selectedValue={(editStatus[value._id])?editStatus[value._id]:''}></SelectField> ,
                screenshot: (!isEditable) ? <a target="_blank" rel="noopener noreferrer" href={BASE_SCREENSHOT_IMAGE_URL+value.screenshot}>{value.screenshot}</a> : <FileInput label={'Upload Screenshot'} onChange={(file)=>{
                    handleEditScreenshotChange(value._id,file)
                }} /> ,
                action: (isEditable) ?
                    <div className={'flex flex-row gap-2 justify-center'}>
                        <Button className={'rounded-md bg-transparent border px-2'} onClick={() => {
                            onHandleAction(value._id)
                        }}>
                            Submit
                        </Button>
                        <Button className={'rounded-md bg-transparent border px-2'} onClick={() => {
                            handleEditChange(value._id, false)
                            handleEditScreenshotChange(value._id,'')
                            handleEditStatusChange(value._id,'')
                            handleEditTransactionChange(value._id,'')
                        }}>
                            Cancel
                        </Button>
                    </div>
                    :
                    <div className={'flex flex-row gap-2 justify-center'}>
                        <Button className={'rounded-md bg-transparent border px-2'} onClick={() => {
                            handleEditChange(value._id, true)
                        }}>
                            Edit
                        </Button>
                    </div>
        }
        } else {
            return {
                srno: index + 1,
                username: value.username,
                details: details,
                transactionId: value.transactionId,
                withdrawAmount: value.amount,
                walletcoins: value.walletCoins,
                status: value.status,
                screenshot: <a target="_blank" rel="noopener noreferrer"
                               href={BASE_SCREENSHOT_IMAGE_URL + value.screenshot}>{value.screenshot}</a>
            }
        }

    }) : []

    const columns = [
        {Header: 'Srno', accessor: 'srno'},
        {Header: 'Username', accessor:  'username'},
    {Header : 'User Wallet Coins', accessor:  "walletcoins"},
    {Header:  'Details', accessor:  'details'},
    { Header: 'Transaction ID', accessor: 'transactionId' },
    { Header: 'Withdraw Amount', accessor: 'withdrawAmount' },
    { Header: 'Payment status', accessor: 'status' },
    { Header: 'Screenshot', accessor: 'screenshot' },
    { Header: 'Action', accessor: 'action' },
    ]
    return (
        <div className={'flex flex-col gap-10 p-4 justify-center  mt-5'}>
            <h1 className={'text-3xl'}>Withdraw List</h1>
            <AdminInfoCard/>
            <MyTable columns={columns} data={renderedData}/>
        </div>
    )
}
export default ListWithDraw;
