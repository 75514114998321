import FormCard from "../Form/FormCard";
import TextInput from "../Form/TextInput";
import Button from "../Form/Button";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {NEW_DEPOSIT_REQUEST, NEW_DEPOSIT_RESET} from "../../constants/depositConstants";
import {addDeposit, getPaymentList} from "../../actions/depositAction";
import {toast} from "react-toastify";
import {CLEAR_ERRORS} from "../../constants/userConstant";
import LoadingScreen from "../Form/Loading";
import FileInput from "../Form/FileInput";
import {upload} from "@testing-library/user-event/dist/upload";
import { BASE_SCREENSHOT_IMAGE_URL } from "../../utils/constant";

const MakePayment = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {loading,success,error} = useSelector(state=> state.newDeposit);
    const {listPayment} = useSelector(state => state.listPayment);

    const [amount,setAmount] = useState("");
    const [transactionId ,setTransactionId] = useState("");
    const [uploadFile,setUploadFile] = useState("")
    const {token,user} = useSelector(state => state.user)
    const depositeProcess = [
        "Simply make your payment using the provided details.",
        "Take a quick screenshot of the transaction, ensuring the Unique Transaction Reference (UTR) number is clearly visible.",
        "In case you encounter any issues, feel free to reach out to our +918569854785 team for further assistance.",
        "Please ensure that your uploaded screenshot matches the examples provided."
    ];
    const renderedList = depositeProcess.map((item,index)=>{
        return (
            <li key={index} className={'font-semibold text-md text-red-500'}>
                {index + 1}. {item}
            </li>
        )
    })
    const onHandleSubmit = (e) =>{
        e.preventDefault();
        if(amount === ""){
            toast.error('Please Enter Amount');
            return;
        }
        if(transactionId === ""){
            toast.error('Please Transaction Id');
            return;
        }
        if(uploadFile === ""){
            toast.error('Please Upload File');
            return;
        }
        if(amount && transactionId && uploadFile){
            const formData = new FormData();
            formData.append('screenshot',uploadFile);
            formData.append('amount', amount);
            formData.append('transactionId', transactionId);
            dispatch(addDeposit(token,formData))
        }
    }

    const onFetchPayment = async () =>{
        await dispatch(getPaymentList())
    }

    useEffect(() => {
        if(!token || !user){
            navigate('/login')
        }
        onFetchPayment();
    }, [token,user]);

    useEffect(() => {
        if (error) {
            toast.error(error.response.data.message);
            dispatch({type : CLEAR_ERRORS})
            return;
        }
        if (success) {
            toast.success("Deposit Successful Requested")
            dispatch({ type: NEW_DEPOSIT_RESET });
            navigate("/depositeHistory");
        }
    }, [dispatch, error, success]);

    return(
        (loading) ? <LoadingScreen/>:
        <FormCard className={'w-full lg:w-3/4 flex flex-col gap-10 mx-auto'} onSubmit={onHandleSubmit}>
            <div className={'flex flex-col lg:flex-row gap-24 items-center'}>
                <div className={'w-full'}>
                    <span className={'font-bold text-lg text-red-500'}>Deposite Process: </span>
                    <ul>
                        {renderedList}
                    </ul>
                </div>
                <div className={'w-full'}>
                    <span className={'font-bold text-lg text-red-500'}>Demo Screenshots :- </span>
                    <img className={'max-h-40'} src={require('../../assests/images/demoScreenshot.png')}/>
                </div>
            </div>
            <div className={'flex flex-col lg:flex-row gap-24'}>
                <div className={'flex flex-col w-full gap-5'}>
                    <span className={'font-bold text-lg text-red-500'}>Payment Details :- </span>
                    {listPayment?
                     <FormCard className={'flex flex-col w-full gap-4'}>
                       {listPayment.paytm?<label htmlFor="name">Paytm-{listPayment.paytm}</label>:null}
                       {listPayment.phonepay?<label htmlFor="upiId">Phonepay- {listPayment.phonepay} </label>:null}
                       {listPayment.googlepay?<label htmlFor="accountNumber">Googlepay- {listPayment.googlepay}  </label>:null}
                       {listPayment.upiId?<label htmlFor="ifscCode"> UPI ID- {listPayment.upiId} </label> : null }
                    </FormCard>
                    :null}
                   
                </div>
                <div className={'w-full flex flex-col gap-5'}>
                    <span className={'font-bold text-lg text-red-500'}>Deposit Information :- </span>
                    <TextInput type={'text'} className={'lg:w-2/3'} placeholder={"100"} value={amount} onChange = {(e)=>{setAmount(e.target.value)}} />
                    <TextInput type={'text'} className={'lg:w-2/3'} placeholder={"Transaction ID"}  value={transactionId} onChange = {(e)=>{setTransactionId(e.target.value)}}/>
                    {/*<TextInput type={'text'} className={'w-2/3'} placeholder={"Status"}/>*/}
                    <FileInput label={'Upload Screenshot'} onChange = {setUploadFile}/>
                    <div className={'flex flex-row lg:w-2/3 gap-5'}>
                        <Button className={'w-full p-2 rounded-md  text-white'}>
                            BACK
                        </Button>
                        <Button className={'w-full p-2 rounded-md  text-white'} type={"submit"}>
                            SUBMIT
                        </Button>
                    </div>
                </div>
            </div>
            <div className={'lg:w-2/5'}>
                {listPayment.qrscreenshot?<img src={BASE_SCREENSHOT_IMAGE_URL+listPayment.qrscreenshot}/> : null }
            </div>
        </FormCard>
    )
}
export default MakePayment;
