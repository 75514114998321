import React from 'react';
import BackgroundImage from "../../assests/images/header.jpeg";
import Team1Image from "../../assests/images/team1.png";
import {BASE_LOGO_IMAGE_URL, teamLogo, teams, teamShortForm} from "../../utils/constant";
import {extractDate, teamShortName} from "../../utils";

export default function MatchCard({teamA,teamB,date,time,matchNo,venue,minAmount,maxAmount,teamALogo,teamBLogo}) {

        return (
        <div className='flex flex-col relative md:w-1/2 gap-10 mb-10'>
            <div className={'h-36 absolute flex flex-col text-lg text-white font-bold ml-2'} >
                <h1 className={'w-full text-center'}>
                    Match : {matchNo + " "}
                </h1>
                {/*<h1>*/}
                {/*    {"MATCH :"+matchNo + teamShortForm[teamA] + " VS " + teamShortForm[teamB]}*/}
                {/*</h1>*/}
                {/*<h1>*/}
                {/*    INDIAN PREMIER LEAGUE, 2024*/}
                {/*</h1>*/}
                {/*<h1>*/}
                {/*    Venue : {venue}*/}
                {/*</h1>*/}
            </div>
            <img className='h-36 rounded-lg' src={BackgroundImage}/>
            <div className='flex flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-center'>
                        {extractDate(date)}</div>
                    <div>
                        {time}
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center'>
                    <div className='flex flex-col justify-center items-center'>
                        <img className={'max-h-16'} src={BASE_LOGO_IMAGE_URL + teamALogo}/>
                        <h1>
                            {teamA}

                        </h1>
                    </div>
                    <div>
                        <img src={require('../../assests/images/versuslogo.png')}/>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <img className={'max-h-16'} src={BASE_LOGO_IMAGE_URL + teamBLogo}/>
                        <h1>
                            {teamB}
                        </h1>
                    </div>
                </div>
            </div>
            {/*<div*/}
            {/*    className='flex flex-row gap-4 text-white rounded-b-md text-center bottom-0 w-full h-16 bg-[#383838]  justify-center items-center'>*/}
            {/*    <div className='border-white border-r-2 p-4 flex flex-col font-bold text-lg'>*/}
            {/*    <span>*/}
            {/*        0*/}
            {/*    </span>*/}
            {/*        <span>*/}
            {/*        Days*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*    <div className='border-white border-r-2 p-4  flex flex-col font-bold text-lg'>*/}
            {/*    <span>*/}
            {/*        0*/}
            {/*    </span>*/}
            {/*        <span>*/}
            {/*     HOURS*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*    <div className='border-white border-r-2 p-4 flex flex-col font-bold text-lg'>*/}
            {/*    <span>*/}
            {/*        0*/}
            {/*    </span>*/}
            {/*        <span>*/}
            {/*        MINUTES*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*    <div className='flex flex-col font-bold text-lg'>*/}
            {/*    <span>*/}
            {/*        0*/}
            {/*    </span>*/}
            {/*        <span>*/}
            {/*        SECONDS*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}
