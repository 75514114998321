export const BASE_SCREENSHOT_IMAGE_URL = 'https://royalbuzzonline.com/uploads/screenshots/';
export const BASE_LOGO_IMAGE_URL = 'https://royalbuzzonline.com/uploads/logos/';
export const BASE_URL = 'https://royalwin.onrender.com'


export const teams = [
    'Chennai Super Kings',
    'Royal Challengers Bengaluru',
    'Delhi Capitals',
    'Gujrat Titans',
    'Kolkata Knight Riders',
    'Lucknow Super Giants',
    'Mumbai Indians',
    'Punjab Kings',
    'Rajsthan Royals',
    'Sunrisers Hyderabad'

]

export const teamLogo = {
    'Chennai Super Kings': require('../assests/images/TeamLogo/CSK.png'),
    'Royal Challengers Bengaluru': require('../assests/images/TeamLogo/RCB.png'),
    'Delhi Capitals': require('../assests/images/TeamLogo/DC.jpg'),
    'Gujrat Titans': require('../assests/images/TeamLogo/GT.png'),
    'Kolkata Knight Riders': require('../assests/images/TeamLogo/KKR.png'),
    'Lucknow Super Giants': require('../assests/images/TeamLogo/LSG.png'),
    'Mumbai Indians': require('../assests/images/TeamLogo/MI.png'),
    'Punjab Kings': require('../assests/images/TeamLogo/PK.png'),
    'Rajsthan Royals': require('../assests/images/TeamLogo/RR.png'),
    'Sunrisers Hyderabad': require('../assests/images/TeamLogo/SRH.png')
}

export const teamShortForm = {
    'Chennai Super Kings': 'CSK',
    'Royal Challengers Bengaluru': 'RCB',
    'Delhi Capitals': 'DC',
    'Gujrat Titans': 'GT',
    'Kolkata Knight Riders': 'KKR',
    'Lucknow Super Giants': 'LSG',
    'Mumbai Indians': 'MI',
    'Punjab Kings': 'PBKS',
    'Rajsthan Royals': 'RR',
    'Sunrisers Hyderabad': 'SRH'
}
