import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import Layout from "../components/Dashboard/Layout";
import Button from "../components/Form/Button";
import SubNavbar from "../components/SubNavbar";
import FormCard from "../components/Form/FormCard";
import TextInput from "../components/Form/TextInput";
import Checkbox from "../components/Form/Checkbox";
import MakeDeposite from "../components/Deposite/MakeDeposite";
import MakePayment from "../components/Deposite/MakePayment";

const DepositePage = () =>{
    const {user,token,isAuthenticated} = useSelector(state => state.user);
    const navigate = useNavigate()
    const navbarElement = [
        {
            name : 'Deposit',
            path : '/deposite'
        },
        {
            name : 'Deposit History',
            path : '/depositeHistory'
        },
        {
            name : 'Wallet History',
            path : '/walletHistory'
        }
    ]
    useEffect(() => {
        if(!user || !token || !isAuthenticated){
            navigate('/login')
        }
    }, [user,token,isAuthenticated]);
    return (
        <Layout className={'flex flex-col gap-10 mb-10'}>
            <SubNavbar navbarElement={navbarElement} className={'ml-0 lg:ml-10 flex flex-row gap-7'} />
            <div className={'ml-10 flex flex-row items-center gap-5'}>
                <div className={'w-2 h-2 rounded bg-red-600'}></div>
                <text className={'text-lg font-bold'}>MAKE A DEPOSIT</text>
                <div className={'w-1/12 h-1 bg-red-600'}></div>
            </div>
            <MakePayment/>
        </Layout>
    )
}
export default DepositePage
