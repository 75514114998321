import React, {useEffect, useState} from 'react';
import Layout from './Layout';
import MatchCard from './MatchCard';
import Title from "../Title";
import SubTitle from "../SubTitle";
import VersusCard from "./VersusCard";
import Button from "../Form/Button";
import SelectField from "../Form/SelectField";
import TextInput from "../Form/TextInput";
import {useDispatch, useSelector} from 'react-redux';
import {addBet, getBetMatch} from '../../actions/betAction';
import {addMatch, getTodaysMatch} from '../../actions/matchAction';
import {getDeposites} from "../../actions/depositAction";
import {useNavigate} from "react-router-dom";
import {loadUser} from "../../actions/userAction";
import {ADD_BET_RESET} from "../../constants/betConstants";
import LoadingScreen from "../Form/Loading";
import {toast} from "react-toastify";
import {CLEAR_ERRORS} from "../../constants/userConstant";
import {extractTimeString} from "../../utils";

export default function BetMatch() {

    const dispatch = useDispatch();
    const [matchId, setMatchId] = useState('');
    const [predictToss, setPredictToss] = useState('');
    const [predictAmount, setPredictAmount] = useState('');
    const [selectedIndex,setSelectedIndex] = useState(null);
    const {matches} = useSelector(state=>state.listMatches)
    const {betMatches}=useSelector(state=>state.listBetMatches)
    const {success,loading,error} = useSelector(state=>state.bet);
    const {token,user,isAuthenticated} = useSelector(state=> state.user);
    const navigate = useNavigate()

    useEffect(() => {
        if(!user || !isAuthenticated || !token){
            navigate('/login')
        }
        dispatch(getTodaysMatch())
        dispatch(getBetMatch(token))
    }, []);

    useEffect(()=>{
        if(error){
            toast.error(error.response.data.message);
            dispatch({type : CLEAR_ERRORS})
            return;
        }
        if(success){
            toast.success('Bet Successful');
            setPredictToss(false)
            setPredictAmount(0)
            dispatch(loadUser(token))
            dispatch(getBetMatch(token))
            dispatch({ type: ADD_BET_RESET });
            navigate('/betList')
        }
    },[success,loading,error])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(predictToss == ''){
            toast.error('Please Select Team')
            return;
        }
        if(predictAmount > user.walletCoins){
            toast.error('You don\'t have enough money')
            return;
        }
        if(predictAmount < matches[selectedIndex].minAmount || predictAmount > matches[selectedIndex].maxAmount){
            toast.error('You have entered amount less than min amount or more than max amount')
            return;
        }
        await dispatch(addBet(matches[selectedIndex]._id, predictToss, predictAmount,token))
    }

    // const addMatchHandler = async ({ id, teamA, teamB, date, time, venue }) => {
    //     setMatchId(id);
    //     await dispatch(addMatch(teamA, teamB, date, time, venue))
    // }

    const renderedVersus = matches && betMatches?matches.map((teamVs, index) => {
        const hasExistingBet = betMatches.some(bet => bet.matchId.toString() === teamVs._id.toString());

        return (
            (index === selectedIndex) || hasExistingBet ? null :
                    <div className={'flex flex-col lg:flex-row w-full justify-center items-center gap-8'} key={index}>
                        <VersusCard teamA={teamVs.teamA} teamB={teamVs.teamB} date={teamVs.date} time={extractTimeString(teamVs.date)} teamALogo={teamVs.teamALogo} teamBLogo={teamVs.teamBLogo}/>
                        <Button className={'px-8 py-5 rounded-lg text-white'}
                                onClick={() => setSelectedIndex(index)}>
                            Bet Now
                        </Button>
                    </div>
        )
    }) : null;
    return (
        (loading) ? <LoadingScreen/>:
        <Layout className={'flex flex-col w-full h-full gap-10 mb-10'}>
            <div className={'flex flex-col lg:flex-row gap-10 w-full h-full p-10 border-b-4'}>
                <div
                    className={'w-full flex flex-col gap-10 border-b-2 lg:border-r-2 lg:border-b-0 justify-center items-center'}>
                    <Title className={'text-5xl font-bold text-left'} displayText={'BET NOW!!!'}/>
                    {selectedIndex!=null?<MatchCard teamA={matches[selectedIndex].teamA} teamB={matches[selectedIndex].teamB} date={matches[selectedIndex].date} time={extractTimeString(matches[selectedIndex].date)} venue={matches[selectedIndex].venue} matchNo={matches[selectedIndex].matchNo} minAmount={matches[selectedIndex].minAmount} maxAmount={matches[selectedIndex].maxAmount} teamALogo={matches[selectedIndex].teamALogo} teamBLogo={matches[selectedIndex].teamBLogo} />:null}
                </div>
                {selectedIndex!=null?
                <div className={'w-full flex flex-col gap-14 justify-center items-center'}>
                    <SubTitle className={'text-[32px] font-bold'} displayText={"PREDICT WHO WILL WIN THE TOSS"} />
                    <div className={'w-full flex flex-col lg:flex-row gap-10 justify-center items-center'}>
                        <div className={'lg:w-2/5 flex flex-col gap-5'}>
                            <SelectField
                                title={'Choose Team'}
                                options={[
                                    matches[selectedIndex].teamA,
                                    matches[selectedIndex].teamB
                                ]}
                                selectedValue={predictToss}
                                setSelectedValue={setPredictToss}
                            />
                            <label>Minimum Amount -: {matches[selectedIndex].minAmount}</label>
                            <label>Maximum Amount -: {matches[selectedIndex].maxAmount}</label>
                        </div>
                        <div className={'lg:w-2/5 flex flex-col gap-5'}>
                            <TextInput
                                type="number"
                                min={100}
                                value={predictAmount}
                                onChange={(e) => setPredictAmount(e.target.value)}
                                placeholder={'Enter Amount'}
                                className={'ring-1 ring-gray-400 p-2'}
                            />
                            <label>Total Amount -: ₹{user.walletCoins} </label>
                            <br/>
                        </div>
                    </div>
                    {selectedIndex != null ? <Button className={'rounded-lg p-4 px-10'} onClick={handleSubmit}>Place Bet</Button>:null}
                </div>:null}
            </div>
            <div className={'w-full flex flex-col gap-10 justify-center items-center'}>
                <Title className={'text-5xl font-bold text-left'} displayText={'UPCOMING MATCH'} />
                {/*<SubTitle className={'w-full md:w-2/3 lg:w-2/5 text-xl font-semibold'}*/}
                {/*    displayText={'Gaming Helper Shows Real Time Odds. The Higher Stakes You Can Get. We place your bets in various bMakers to do highest liquidity'} />*/}
                {renderedVersus}
            </div>
        </Layout>
    );
}
