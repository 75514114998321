import Button from "./Form/Button";
import {useNavigate} from "react-router-dom";
import classnames from "classnames";

const SubNavbar = ({className,navbarElement}) =>{
    const classNames = classnames(className)
    const navigate = useNavigate()
    const renderedButton = navbarElement.map((value,index)=>{
        const currentPath = window.location.pathname;
        return (
            <Button className={`px-5 ${currentPath === value.path? 'text-white' : 'bg-transparent text-black  ring-2 ring-gray-400' }`} onClick={()=>{
                navigate(value.path)
            }}>
                {value.name}
            </Button>
        )
    })
    return (
        <div className={classNames}>
            {renderedButton}
        </div>
    )
}
export default SubNavbar
