import {
    ADD_MATCH_ADMIN_FAIL,
    ADD_MATCH_ADMIN_REQUEST,
    ADD_MATCH_ADMIN_RESET,
    ADD_MATCH_ADMIN_SUCCESS,
    ADD_TOSS_WINNER_ADMIN_FAIL,
    ADD_TOSS_WINNER_ADMIN_REQUEST,
    ADD_TOSS_WINNER_ADMIN_RESET,
    ADD_TOSS_WINNER_ADMIN_SUCCESS, ADMIN_USER_LOGOUT,
    GET_USER_STATS_FAIL,
    GET_USER_STATS_REQUEST,
    GET_USER_STATS_SUCCESS,
    LIST_DEPOSIT_ADMIN_FAIL,
    LIST_DEPOSIT_ADMIN_REQUEST,
    LIST_DEPOSIT_ADMIN_RESET,
    LIST_DEPOSIT_ADMIN_SUCCESS,
    LIST_MATCHES_ADMIN_FAIL,
    LIST_MATCHES_ADMIN_REQUEST,
    LIST_MATCHES_ADMIN_RESET,
    LIST_MATCHES_ADMIN_SUCCESS,
    LIST_WITHDRAW_ADMIN_FAIL,
    LIST_WITHDRAW_ADMIN_REQUEST,
    LIST_WITHDRAW_ADMIN_RESET,
    LIST_WITHDRAW_ADMIN_SUCCESS,
    LOGIN_ADMIN_USER_FAIL,
    LOGIN_ADMIN_USER_REQUEST,
    LOGIN_ADMIN_USER_SUCCESS,
    UPDATE_DEPOSIT_ADMIN_FAIL,
    UPDATE_DEPOSIT_ADMIN_REQUEST,
    UPDATE_DEPOSIT_ADMIN_RESET,
    UPDATE_DEPOSIT_ADMIN_SUCCESS,
    UPDATE_LIST_PAYMENT_FAIL,
    UPDATE_LIST_PAYMENT_REQUEST,
    UPDATE_LIST_PAYMENT_RESET,
    UPDATE_LIST_PAYMENT_SUCCESS,
    UPDATE_MATCH_STATUS_ADMIN_FAIL,
    UPDATE_MATCH_STATUS_ADMIN_REQUEST,
    UPDATE_MATCH_STATUS_ADMIN_RESET,
    UPDATE_MATCH_STATUS_ADMIN_SUCCESS,
    UPDATE_WITHDRAW_ADMIN_FAIL,
    UPDATE_WITHDRAW_ADMIN_REQUEST, UPDATE_WITHDRAW_ADMIN_RESET,
    UPDATE_WITHDRAW_ADMIN_SUCCESS
} from "../constants/adminConstants";
import {
    LIST_DEPOSIT_FAIL,
    LIST_DEPOSIT_REQUEST,
    LIST_DEPOSIT_RESET,
    LIST_DEPOSIT_SUCCESS
} from "../constants/depositConstants";

export const adminReducer =  (state = { adminUser: {}}, { type, payload }) => {
    switch (type){
        case LOGIN_ADMIN_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_ADMIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                adminUser: payload.data,
                token : payload.userToken
            };
        case LOGIN_ADMIN_USER_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: payload,
            };
        case ADMIN_USER_LOGOUT:
            return {
                loading: false,
                isAuthenticated: false,
                adminUser: {},
                token : null
            }
        default :
            return state
    }
}
export const userStatsAdminReducer =  (state = { stats: {}}, { type, payload }) => {
    switch (type){
        case GET_USER_STATS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                stats: payload.data,
            };
        case GET_USER_STATS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default :
            return state
    }
}

export const listDepositAdminReducer = (state = { listDepositsAdmin: []}, { type, payload }) => {
    switch (type) {
        case LIST_DEPOSIT_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LIST_DEPOSIT_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                listDepositsAdmin: payload.deposits,
            };
        case LIST_DEPOSIT_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case LIST_DEPOSIT_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
export const listWithdrawAdminReducer = (state = { listWithdrawsAdmin: []}, { type, payload }) => {
    switch (type) {
        case LIST_WITHDRAW_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LIST_WITHDRAW_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                listWithdrawsAdmin: payload.withdraws,
            };
        case LIST_WITHDRAW_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case LIST_WITHDRAW_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
export const depositAdminReducer = (state = { deposit :{}}, { type, payload }) => {
    switch (type) {
        case UPDATE_DEPOSIT_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_DEPOSIT_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                deposit: payload.deposit,
            };
        case UPDATE_DEPOSIT_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_DEPOSIT_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}

export const addMatchAdminReducer =  (state = { match :{}}, { type, payload }) => {
    switch (type) {
        case ADD_MATCH_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_MATCH_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                match: payload.deposit,
            };
        case ADD_MATCH_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case ADD_MATCH_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
export const listAllMatchesReducers =  (state = { listAllMatches: []}, { type, payload }) => {
    switch (type) {
        case LIST_MATCHES_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LIST_MATCHES_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                listAllMatches: payload.matches,
            };
        case LIST_MATCHES_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case LIST_MATCHES_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
export const updateTossWinner =  (state = { match: {}}, { type, payload }) => {
    switch (type) {
        case ADD_TOSS_WINNER_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_TOSS_WINNER_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                match: payload.match,
            };
        case ADD_TOSS_WINNER_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case ADD_TOSS_WINNER_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}
export const updateMatchStatus =  (state = { match: {}}, { type, payload }) => {
    switch (type) {
        case UPDATE_MATCH_STATUS_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_MATCH_STATUS_ADMIN_SUCCESS:
            return {
                loading: false,
                isUpdated: payload.success,
                match: payload.match,
            };
        case UPDATE_MATCH_STATUS_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_MATCH_STATUS_ADMIN_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        default :
            return state
    }
}
export const withdrawAdminReducer = (state = { withdraw :{}}, { type, payload }) => {
    switch (type) {
        case UPDATE_WITHDRAW_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_WITHDRAW_ADMIN_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                deposit: payload.withdraw,
            };
        case UPDATE_WITHDRAW_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_WITHDRAW_ADMIN_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
}

export const updatePaymentReducer = (state = { success : false}, { type, payload }) => {
    switch (type) {
        case UPDATE_LIST_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_LIST_PAYMENT_SUCCESS:
            return {
                loading: false,
                success: payload.success,
            };
        case UPDATE_LIST_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_LIST_PAYMENT_RESET:
            return {
                ...state,
                success: false,
            };
        default :
            return state
    }
} 