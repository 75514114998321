import Title from "../Title";
import FormCard from "../Form/FormCard";
import TextInput from "../Form/TextInput";
import Button from "../Form/Button";
import React from "react";

const EditProfile = () =>{
    return (
        <>
            <Title displayText={'Edit Profile'}/>
            <FormCard className={'flex flex-col gap-10 w-1/2 mx-auto'}>
                <div className={'flex flex-row gap-5'}>
                    <TextInput className={'w-full'} placeholder={'Enter Full Name'}/>
                    <TextInput className={'w-full'} placeholder={'Enter Phone Number'}/>
                </div>
                <div className={'flex flex-row gap-5'}>
                    <TextInput className={'w-full'} placeholder={'Enter Phonepe Number'}/>
                    <TextInput className={'w-full'} placeholder={'Enter G-PAY Number'}/>
                </div>
                <div className={'flex flex-col gap-5'}>
                    <label>
                        Upload Profile Picture
                    </label>
                    <TextInput className={'w-1/2'} placeholder={'Upload Screenshot'}/>
                </div>
                <Button className={'py-2 rounded-md w-1/5 mx-auto'}>Submit</Button>
            </FormCard>
        </>
    )
}
export default EditProfile
