import classnames from 'classnames';

const FormCard = ({className,children,...props}) => {
    const classNames = classnames('px-12 py-14 stroke-[0.5px] shadow-2xl',className)
  return (
      <form className={classNames} {...props}>
          {children}
      </form>
  )
}
export default FormCard;
