import React from 'react'
import Header from './Header'
import classnames from "classnames";

export default function Layout({ className,children }) {
    const classNames = classnames(className)
    return (
        <div className={classNames}>
            <Header />
            {children}
        </div>
    )
}
