import Layout from "../components/Dashboard/Layout";
import Table from "../components/Form/Table";
import React, {useEffect, useState} from "react";
import FormCard from "../components/Form/FormCard";
import TextInput from "../components/Form/TextInput";
import Button from "../components/Form/Button";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {addWithdraw, getWithdraws} from "../actions/withdrawAction";
import {NEW_DEPOSIT_RESET} from "../constants/depositConstants";
import {NEW_WITHDRAW_RESET} from "../constants/withdrawConstants";
import {loadUser} from "../actions/userAction";
import {extractDate} from "../utils";
import LoadingScreen from "../components/Form/Loading";
import {toast} from "react-toastify";
import {BASE_SCREENSHOT_IMAGE_URL} from "../utils/constant";
import RadioButton from "../components/Form/RadioButton";

const WithdrawPage = () =>{

    const {user,isAuthenticated,token} = useSelector(state => state.user);
    const navigate = useNavigate()
    const [amount,setAmount] = useState(0)
    const [upiId,setUpiId] = useState("")
    const dispatch = useDispatch();
    const {loading,success,error} = useSelector(state=> state.newWithdraw);
    const {listWithdraws} = useSelector(state=>state.listWithdraw);
    const [paymentMethod, setPaymentMethod] = useState('');

    const [bankDetails, setBankDetails] = useState({
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        holderName: '',
    });

    const [walletId,setWalletId] = useState("");
    useEffect(() => {
        if(!user || !isAuthenticated || !token){
            navigate('/login')
        }
        dispatch(getWithdraws(token))
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error.response.data.message);
        }
        if (success) {
            toast.success('Withdraw Requested Successfully');
            dispatch({ type: NEW_WITHDRAW_RESET });
            dispatch(loadUser(token))
            dispatch(getWithdraws(token))
            setAmount(0)
        }
    }, [dispatch, error, success]);

    const onHandleSubmit = (e) =>{
        e.preventDefault();
        if(amount === 0){
            toast.error('Enter Amount to withdraw');
            return;
        }
        const formData = new FormData();
        formData.append("amount",amount)
        formData.append("paymentMethod",paymentMethod)
        if(paymentMethod === 'walletId'){
            formData.append("walletId",walletId);
        }else if(paymentMethod === "upiId") {
            formData.append("upiId",upiId);
        }else if(paymentMethod === "bankDetails"){
            formData.append("bankDetails", JSON.stringify(bankDetails));
        }else {
            toast.error('Please Select the payment mode');
            return ;
        }
        // const jsonData = Object.fromEntries(formData.entries());
        // console.log(jsonData.bankDetails)
        dispatch(addWithdraw(formData,token))
    }
    const onHandleChange = (e) =>{
        const value = e.target.value;
        const walletCoins = user.walletCoins
        if(value > walletCoins){
            toast.error('Above Available Coins')
        }else{
            setAmount(value)
        }
    }

    const renderedData = listWithdraws ?  listWithdraws.map((data,index)=>{
        let details = ""
        if(data.upiId){
            details = <label>UPI ID :{data.upiId}</label>
        }else if(data.walletId){
            details = <label> Wallet ID : {data.walletId}</label>
        }else if(data.bankDetails){
            const {accountNumber,
                ifscCode,
                bankName,
                holderName} = data.bankDetails;
            details = <div className={'flex flex-col'}>
                <label>Account Number : {accountNumber}</label>
                <label>Ifsc Code : {ifscCode}</label>
                <label>Bank Name : {bankName}</label>
                <label>Holder Name : {holderName}</label>

            </div>
        }

        return (
            {
                srno : index+1,
                transactionId :data.transactionId ? data.transactionId : '-',
                screenshot : data.screenshot?<a target="_blank" rel="noopener noreferrer" href={BASE_SCREENSHOT_IMAGE_URL+data.screenshot}>{data.screenshot}</a>:'-',
                depositAmount:data.amount,
                status : data.status,
                details :details,
                date : extractDate(data.createdAt)
            }
        )
    }) : null

    const columns = [
        { Header: 'Srno', accessor: 'srno' },
        { Header: 'TransactionId', accessor: 'transactionId' },
        { Header: 'Screenshot', accessor: 'screenshot' },
        { Header: 'DepositAmount', accessor: 'depositAmount' },
        { Header: 'Details' , accessor: 'details'},
        { Header: 'Status', accessor: 'status' },
        { Header: 'Date', accessor: 'date' },
    ]
    const randomId = Math.random().toString(36).slice(2, 7)
    return(
        (loading) ? <LoadingScreen/>:
            <Layout className={'flex flex-col gap-10 mb-10'}>
            <div className={'ml-10 flex flex-row items-center gap-5'}>
                <div className={'w-2 h-2 rounded bg-red-600'}></div>
                <text className={'text-lg font-bold'}>Wallet Request</text>
                <div className={'w-1/12 h-1 bg-red-600'}></div>
            </div>
                <FormCard className={'flex flex-col gap-10 w-full lg:w-1/2 mx-auto'} onSubmit={onHandleSubmit}>
                    <div className={'flex flex-col lg:flex-row gap-5 lg:gap-10 items-center justify-between'}>
                        <span
                            className={'font-bold text-lg text-red-500'}>Available amount : {user ? user.walletCoins : ""} </span>
                    </div>
                    <div className={'flex flex-col items-start lg:flex-row gap-5 lg:gap-10 lg:items-center'}>
                        <span className={'font-bold'}>Enter Amount : </span>
                        <TextInput className={'w-full lg:w-1/3'} placeholder={"Enter Amount"} value={amount}
                                   onChange={onHandleChange}></TextInput>
                    </div>
                    <div className={'flex flex-col items-start lg:flex-row gap-5 lg:gap-14 lg:items-center'}>
                        <span className={'font-bold'}>Enter Mode: </span>

                        <RadioButton randomId={randomId} labelText={'Wallet Id'} className={'flex flex-row gap-2 font-semibold'} onClick={()=>{
                            setPaymentMethod('walletId')
                        }} />
                        <RadioButton randomId={randomId} labelText={'Bank Details'} className={'flex flex-row gap-2 font-semibold'} onClick={()=>{
                            setPaymentMethod('bankDetails')
                        }}/>
                        <RadioButton randomId={randomId} labelText={'Upi Id'} className={'flex flex-row gap-2 font-semibold'} onClick={()=>{
                            setPaymentMethod('upiId')
                        }}/>
                    </div>
                    <div>
                        {paymentMethod === 'upiId'  && (
                            <div
                                className={'flex flex-col items-start lg:flex-row gap-5 lg:gap-14 lg:items-center'}>
                                <span className={'font-bold'}>Enter Upi ID: </span>
                                <TextInput className={'w-full lg:w-1/3'} placeholder={"Enter UPI ID"} value={upiId}
                                           onChange={(e) => {
                                               setUpiId(e.target.value)
                                           }}></TextInput>
                            </div>
                        )}
                        {paymentMethod === 'bankDetails' && (
                            <div
                                className={'flex flex-col lg:flex-col gap-5 lg:gap-14 '}>
                                <span className={'font-bold'}>Enter Bank Details : </span>
                                <TextInput
                                    className={'w-full lg:w-1/3'}
                                    placeholder={"Enter Account number \n"}
                                    value={bankDetails.accountNumber}
                                    onChange={(e) => {
                                        setBankDetails({ ...bankDetails, accountNumber: e.target.value });
                                    }}
                                ></TextInput>
                                <TextInput
                                    className={'w-full lg:w-1/3'}
                                    placeholder={"Enter IFSC code \n"}
                                    value={bankDetails.ifscCode}
                                    onChange={(e) => {
                                        setBankDetails({ ...bankDetails, ifscCode: e.target.value });
                                    }}
                                ></TextInput>
                                <TextInput
                                    className={'w-full lg:w-1/3'}
                                    placeholder={"Enter Bank name"}
                                    value={bankDetails.bankName}
                                    onChange={(e) => {
                                        setBankDetails({ ...bankDetails, bankName: e.target.value });
                                    }}
                                ></TextInput>
                                <TextInput
                                    className={'w-full lg:w-1/3'}
                                    placeholder={"Enter Holder name"}
                                    value={bankDetails.holderName}
                                    onChange={(e) => {
                                        setBankDetails({ ...bankDetails, holderName: e.target.value });
                                    }}
                                ></TextInput>
                            </div>
                        )}
                        {paymentMethod === 'walletId' && (
                            <div
                                className={'flex flex-col items-start lg:flex-row gap-5 lg:gap-14 lg:items-center'}>
                                <span className={'font-bold'}>Enter Wallet ID : </span>
                                <TextInput className={'w-full lg:w-1/3'} placeholder={"Enter Wallet ID"} value={walletId}
                                           onChange={(e) => {
                                               setWalletId(e.target.value)
                                           }}></TextInput>
                            </div>
                        )}
                    </div>

                    <Button className={'rounded-md px-4 p-2 w-full  text-white lg:w-1/5'}>Request</Button>
                </FormCard>
                <div className={'ml-10 flex flex-row items-center gap-5'}>
                    <div className={'w-2 h-2 rounded bg-red-600'}></div>
                    <text className={'text-lg font-bold'}>Wallet Request</text>
                    <div className={'w-1/12 h-1 bg-red-600'}></div>
                </div>
                <Table className={'w-2/3 mx-auto rounded-md border-[#FFFFFF] shadow drop-shadow-md'} data={renderedData ? renderedData : null}
                   columns={columns}/>
        </Layout>
    )
}
export default WithdrawPage
