import React from 'react';
import classnames from "classnames";

const MyTable = ({data,columns,className}) => {
    const classNames = classnames('border shadow',className)
    const renderedColumns = columns.map((column,index)=>{
        return (
            <th key={index} scope="col" className="px-3 lg:px-6 py-3 text-center">{column.Header}</th>
        )
    })
    const renderedRows = data.map((item) =>{
        return (
            <tr key={item.name}>  {/* Use a unique key for each row */}
                {columns.map((column) => (
                    <td className="px-3 lg:px-6 py-3 text-center" key={column}>{item[column.accessor]}</td>
                ))}
            </tr>
        )
    });
    return (
        <table className={classNames}>
            <thead>
            <tr className="bg-[#C10930] text-white font-medium">
            {renderedColumns}
            </tr>
            </thead>
            <tbody>
            {renderedRows}
            </tbody>
        </table>

    );
};

export default MyTable;
