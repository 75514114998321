import axios from "../utils/axiosConfig";
import {
    LIST_DEPOSIT_FAIL,
    LIST_DEPOSIT_REQUEST, LIST_DEPOSIT_SUCCESS,
    LIST_PAYMENT_FAIL,
    LIST_PAYMENT_REQUEST,
    LIST_PAYMENT_SUCCESS,
    NEW_DEPOSIT_FAIL,
    NEW_DEPOSIT_REQUEST,
    NEW_DEPOSIT_SUCCESS
} from "../constants/depositConstants";
import {
    UPDATE_DEPOSIT_ADMIN_FAIL,
    UPDATE_DEPOSIT_ADMIN_REQUEST,
    UPDATE_DEPOSIT_ADMIN_SUCCESS
} from "../constants/adminConstants";

export const addDeposit = (token,depositData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_DEPOSIT_REQUEST });
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.post(
            '/api/v1/addDeposit',
            depositData,
            config
        );

        dispatch({
            type: NEW_DEPOSIT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_DEPOSIT_FAIL,
            payload: error,
        });
}}
export const getDeposites = (token) => async (dispatch) => {
    try {
        dispatch({ type: LIST_DEPOSIT_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.get(
            '/api/v1/listDeposit',
            config
        );

        dispatch({
            type: LIST_DEPOSIT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_DEPOSIT_FAIL,
            payload: error.message,
        });
    }}

export const updateDepositStatus = (token,id,approve) => async (dispatch) =>{
    try {
        dispatch({ type: UPDATE_DEPOSIT_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.put(
            '/api/v1/updateDepositStatus',
            { id,approve},
            config
        );

        dispatch({
            type: UPDATE_DEPOSIT_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_DEPOSIT_ADMIN_FAIL,
            payload: error.message,
        });
    }
}

export const getPaymentList = () => async (dispatch) => {
    try {
        dispatch({ type: LIST_PAYMENT_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.get(
            '/api/v1/bank',
            config
        );

        dispatch({
            type: LIST_PAYMENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_PAYMENT_FAIL,
            payload: error.message,
        });
    }}