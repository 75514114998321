import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import Dashboard from "./pages/Dashboard";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {Suspense} from "react";
import DepositePage from "./pages/DepositePage";
import DepositeHistoryPage from "./pages/DepositeHistoryPage";
import WalletHistoryPage from "./pages/WalletHistoryPage";
import WithdrawPage from "./pages/WithdrawPage";
import ProfilePage from "./pages/ProfilePage";
import ProfileLayout from "./routes/ProfileLayout";
import EditProfile from "./components/Profile/EditProfile";
import EditPassword from "./components/Profile/EditPassword";
import WinnerList from "./pages/WinnerList";
import AdminLogin from "./pages/AdminLogin";
import AdminLayout from "./components/Admin/Layout/AdminLayout";
import DepositList from "./pages/DepositList";
import {ScheduleMatch} from "./pages/ScheduleMatch";
import ListMatch from "./pages/ListMatch";
import ListWithDraw from "./pages/ListWithdraw";
import {useDispatch} from "react-redux";
import {ADMIN_USER_LOGOUT} from "./constants/adminConstants";
import { BankDetails } from "./pages/BankDetails";

function App() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
  return (
      <Suspense fallback={<></>} >
          <Routes>
              <Route path={'/adminlogin'} element={<AdminLogin/>} >
              </Route>
              <Route path={'/admin'} element={<AdminLayout/>}>
                  <Route path="/admin/" element={<Navigate replace to="/admin/listMatches" />} /> {/* Redirect root path to /home */}
                  <Route path = {'/admin/listDeposit'} element={<DepositList/>}/>
                   <Route path = {'/admin/scheduleMatch'} element={<ScheduleMatch/>}/>
                  <Route path = {'/admin/listWithdraw'} element={<ListWithDraw/>}/>
                  <Route path = {'/admin/listMatches'} element={<ListMatch/>}/>
                  <Route path = {'/admin/bankdetails'} element={<BankDetails/>}/>

              </Route>
              <Route path="/" element={<Navigate replace to="/login" />} /> {/* Redirect root path to /home */}
              <Route path="/register" element={
                  <RegisterPage/>
              }>
              </Route>
              <Route path="/login" element={
                  <LoginPage/>
              }>
              </Route>
              <Route path={'/dashboard'} element={<Dashboard/>}/>
              <Route path={'/deposite'} element={<DepositePage/>}/>
              <Route path={'/depositeHistory'} element={<DepositeHistoryPage/>}/>
              <Route path={'/walletHistory'} element={<WalletHistoryPage/>}/>
              <Route path={'/withdraw'} element={<WithdrawPage/>}/>
              <Route path={'/betList'} element={<WinnerList/>}/>
              <Route path="/profile" element={<ProfileLayout />}>
                  <Route path="/profile/edit" element={<EditProfile />} />  {/* Nested route */}
                  <Route path="/profile/changepassword" element={<EditPassword />} />  {/* Nested route */}
              </Route>

          </Routes>
      </Suspense>
  );
}

export default App;
