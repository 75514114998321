export const NEW_WITHDRAW_REQUEST = "NEW_WITHDRAW_REQUEST";
export const NEW_WITHDRAW_SUCCESS = "NEW_WITHDRAW_SUCCESS";
export const NEW_WITHDRAW_FAIL = "NEW_WITHDRAW_FAIL";
export const NEW_WITHDRAW_RESET = "NEW_WITHDRAW_RESET";

export const LIST_WITHDRAW_REQUEST = "LIST_WITHDRAW_REQUEST"
export const LIST_WITHDRAW_SUCCESS = "LIST_WITHDRAW_SUCCESS"
export const LIST_WITHDRAW_FAIL = "LIST_WITHDRAW_FAIL"
export const LIST_WITHDRAW_RESET = "LIST_WITHDRAW_RESET"

