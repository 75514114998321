import Button from "./Form/Button";
import classnames from "classnames";

const Advertise = ({title,subTitle,className,action,children}) =>{
    const classNames = classnames(className,'stroke-1')
    return (
        <div className={classNames} style={{
            background : 'rgba(255, 255, 255, 0.8)'
        }}>
        {/*//         <h1 className={'text-xl lg:text-3xl w-full font-bold text-center'}>*/}
        {/*//             {title}*/}
        {/*//         </h1>*/}
        {/*//         <h1 className={'text-md lg:text-lg font-bold'}>*/}
        {/*//             {subTitle}*/}
        {/*//         </h1>*/}
        {/*//         <Button className={'ml-auto mr-10 mb-0 p-4 lg:px-10 '} onClick={action}>*/}
        {/*//             Read More*/}
        {/*//         </Button>*/}
            {children}
        </div>
    )
}
export  default  Advertise
