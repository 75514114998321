import {
    ADD_BET_REQUEST,
    ADD_BET_SUCCESS,
    ADD_BET_FAILURE,
    LIST_BET_REQUEST,
    LIST_BET_FAILURE, LIST_BET_SUCCESS, LIST_BET_TEAM_REQUEST, LIST_BET_TEAM_SUCCESS, LIST_BET_TEAM_FAILURE
} from "../constants/betConstants.js";
import axios from "../utils/axiosConfig.js";
import {LIST_MATCHES_FAIL, LIST_MATCHES_REQUEST, LIST_MATCHES_SUCCESS} from "../constants/matchConstants";

export const addBet = (matchId, predictToss, predictAmount,token) => async (dispatch) => {
    try {
        dispatch({ type: ADD_BET_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.post(
            '/api/v1/addBet',
            { matchId, predictToss, predictAmount },
            config
        );

        dispatch({
            type: ADD_BET_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_BET_FAILURE,
            payload: error,
        });
    }
}
export const getBetMatch = (token) => async (dispatch) => {
    try {
        dispatch({ type: LIST_BET_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }
        console.log(config)

        const { data } = await axios.get(
            '/api/v1/getBetMatches',
            config
        );

        dispatch({
            type: LIST_BET_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_BET_FAILURE,
            payload: error.message,
        });
    }
}
export const getBetMatchByTeamName = (token) => async (dispatch) => {
    try {
        dispatch({ type: LIST_BET_TEAM_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }
        console.log(config)

        const { data } = await axios.get(
            '/api/v1/getBetMatchWithTeamName',
            config
        );

        dispatch({
            type: LIST_BET_TEAM_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_BET_TEAM_FAILURE,
            payload: error,
        });
    }
}
