import {
    LIST_DEPOSIT_FAIL,
    LIST_DEPOSIT_REQUEST, LIST_DEPOSIT_SUCCESS,
    NEW_DEPOSIT_FAIL,
    NEW_DEPOSIT_REQUEST,
    NEW_DEPOSIT_SUCCESS
} from "../constants/depositConstants";
import axios from "../utils/axiosConfig";
import {
    LIST_WITHDRAW_FAIL,
    LIST_WITHDRAW_REQUEST, LIST_WITHDRAW_SUCCESS,
    NEW_WITHDRAW_FAIL,
    NEW_WITHDRAW_REQUEST,
    NEW_WITHDRAW_SUCCESS
} from "../constants/withdrawConstants";
import {
    UPDATE_DEPOSIT_ADMIN_FAIL,
    UPDATE_DEPOSIT_ADMIN_REQUEST,
    UPDATE_DEPOSIT_ADMIN_SUCCESS, UPDATE_WITHDRAW_ADMIN_FAIL,
    UPDATE_WITHDRAW_ADMIN_REQUEST,
    UPDATE_WITHDRAW_ADMIN_RESET,
    UPDATE_WITHDRAW_ADMIN_SUCCESS
} from "../constants/adminConstants";

export const addWithdraw = (withdrawData,token) => async (dispatch) => {
    try {
        dispatch({ type: NEW_WITHDRAW_REQUEST });
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.post(
            '/api/v1/addWithdraw',
            withdrawData,
            config
        );

        dispatch({
            type: NEW_WITHDRAW_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_WITHDRAW_FAIL,
            payload: error.message,
        });
    }}
export const getWithdraws = (token) => async (dispatch) => {
    try {
        dispatch({ type: LIST_WITHDRAW_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.get(
            '/api/v1/listWithdraw',
            config
        );

        dispatch({
            type: LIST_WITHDRAW_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_WITHDRAW_FAIL,
            payload: error,
        });
    }}
export const updateWithdrawStatus = (token,depositData) => async (dispatch) =>{
    try {
        dispatch({ type: UPDATE_WITHDRAW_ADMIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":"Bearer "+token
            },
        }

        const { data } = await axios.put(
                '/api/v1/updateWithdrawStatus',
            depositData,
            config
        );

        dispatch({
            type: UPDATE_WITHDRAW_ADMIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_WITHDRAW_ADMIN_FAIL,
            payload: error.message,
        });
    }
}
