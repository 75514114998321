import {useState} from "react";
import TextInput from "./TextInput";
import Button from "./Button";

const FileInput = ({ onChange,label,preview,...props }) => {
    const [file, setFile] = useState(preview?preview:null);
    const randomId = Math.random().toString(36).slice(2, 7);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFile(reader.result);
                onChange(selectedFile);
            };
            reader.readAsDataURL(selectedFile); // Read the file and create a data URL
        } else {
            setFile(null); // Clear preview if no file selected
        }
// Pass the selected file to the parent component
    };

    return (
        <div className={'w-full justify-center items-center'}>
            <Button className={'border rounded-md p-4 drop-shadow-md text-white'} type={"button"} onClick={() => document.getElementById(randomId).click()}>
                {label}
            </Button>
            <TextInput
                hidden
                id={randomId}
                type="file"
                InputProps={{accept: 'image/*'}} // Accept only image files
                onChange={handleFileChange}
                {...props}
            />
            {file && <img className={'h-32 w-32'} src={file}/>}
        </div>
    );
};

export default FileInput;
